import React, { Component } from 'react';
import './App.css';
import { AxlHeader } from 'axl-reactjs-ui';
import { stores } from './stores/index';

import { Provider, Observer } from 'mobx-react';

import Assignment from './screens/Assignment/index'
import RouteControl from './screens/RouteControl/index'
import Driver from './screens/Driver/index'
import TicketScreen from './screens/Ticket/index'
import BookingSessionScreen from './screens/BookingSession/index'
import DriverPickupTimeContainer from './screens/PickupTime/index'
import {SortCenter, sortLayoutAPI} from './containers/SortLayout'
import {
  RecoilRoot  
} from 'recoil';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      isHideMainMenu: false
    }
    sortLayoutAPI.withBaseUrl(process.env.REACT_APP_API_ROOT)
  }

  componentDidMount() {
    stores.userStore.getUser();
  }

  toogleMenuSlider = () => {
    this.setState({isActive: !this.state.isActive})
  }

  closeMenuSlider = () => {
    this.setState({isActive: false})
  }

  toggleMainMenu = () => this.setState({isHideMainMenu: !this.state.isHideMainMenu});

  logout() {
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?logout=true&next=${encodeURI(window.location.href)}`;
  }

  render() {
    return (
      <RecoilRoot>
      <Provider {...stores}>
        <Router>
          <div className="App">
              <Observer render={() => <AxlHeader
                darkTheme={false}
                title={'OUTBOUND'}
                client={stores.userStore.user}
                onClick={this.toogleMenuSlider}
                logout={this.logout}
                onHandleMainMenu={this.toggleMainMenu} /> } />
            <div className="main-content">
              <Switch>
                <Route path="/drivers/:id" component={Driver} />
                <Route path="/routes/:id" component={Assignment} />
                <Route path="/tickets/:id" component={TicketScreen} />
                <Route path="/booking-sessions/:id" component={BookingSessionScreen} />
                <Route path="/pickup" component={DriverPickupTimeContainer} />
                <Route path='/sortation/:center_id/session/:session_id'>
                  <SortCenter />
                </Route>
                <Route path='/sortation/:center_id'>
                  <SortCenter />
                </Route>
                <Route exact path="/" component={RouteControl} />
                <Route exact path="/routes" component={RouteControl} />
              </Switch>
            </div>
          </div>
        </Router>
      </Provider></RecoilRoot>
    );
  }
}

export default App;
