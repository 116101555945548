import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles';
import TicketBox from '../../components/TicketBox';
import { AxlButton } from 'axl-reactjs-ui';
import QrReader from "react-qr-reader";
import History from '../History';
import TicketMap from '../TicketMap';
import TicketNote from '../TicketNote';

@inject('ticketStore', 'eventStore')
@observer
class TicketRelease extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delay: 300,
      result: "No result",
      showQR: false,
    };
    this.handleError = this.handleError.bind(this)
    this.handleScan = this.handleScan.bind(this)
    this.onVoidTicket = this.onVoidTicket.bind(this)
    this.onUnclaim = this.onUnclaim.bind(this)
  }

  handleScan(v) {
    const { ticketStore, eventStore } = this.props
    if (!v) return;
    console.log(v)
    ticketStore.assignTicket([v]).then((r) => {
      if (r) {
        this.setState({showQR: false});
        eventStore.loadEvents('TK_' + ticketStore.activeTicket.id)
      }
    });
  }

  onVoidTicket() {
    var r = window.confirm("Please confirm you want to void this ticket!");
    if (r == true) {
      console.log('voiding ...')
      const { ticketStore } = this.props
      const { activeTicket } = ticketStore
      ticketStore.voidTicket( activeTicket.id );
    } else  {
      console.log('cancel')
    }
  }

  onUnclaim() {
    var r = window.confirm("Please confirm you want to un-claim this ticket!");
    if (r == true) {
      console.log('un-claiming ...')
      const { ticketStore, eventStore } = this.props
      ticketStore.unassignTicket().then((r) => {
        eventStore.loadEvents('TK_' + ticketStore.activeTicket.id);
      })
    } else  {
      console.log('cancel')
    }
  }

  handleError(v) {
    console.log(v)
  }

  render() {
    const { ticketStore } = this.props
    const { activeTicket, activeDriver } = ticketStore
    const isCompleted = activeTicket != null && activeTicket.status === 'COMPLETED'
    const isClaimed = activeTicket != null && activeTicket.status === 'CLAIMED'
    const isClosed = activeTicket != null && (isClaimed || isCompleted)
    if (!activeTicket) return <div></div>
    return <div style={styles.container}>
      <TicketBox ticket={ activeTicket } driver={activeDriver} />
      { isClosed && <div>
        <div style={{width: 120, display: 'flex', border: 'solid 1px #89f', color: '#128', height: 60, backgroundColor: '#eef', borderRadius: 20, marginLeft: 'auto', marginRight: 'auto', marginBottom: 30, marginTop: 30, alignItems: 'center', justifyContent: 'center'}}>
        <div style={{textAlign: 'center', fontSize: '14px'}}>{ activeTicket.status}</div>
        </div>
        <div style={{lineHeight: 1.5, fontSize: '18px'}}>
          Ticket was used to claim assignment <code>{ activeTicket.item.split('_')[1] }</code>
        </div>
        { !isCompleted && <div>
          <AxlButton circular={true} bg={'black'} block={true} onClick={ this.onUnclaim } >Un-Claim</AxlButton>
          <div style={{fontSize: '0.8em', color: '#444'}}>
            In case of claiming wrong assignment by accident, use <strong>Un-Claim</strong> to reset.
          </div>
        </div> }
      </div>}
      { activeTicket.status === 'VOIDED' && <div>
        <div style={{width: 90, display: 'flex', border: 'solid 1px #ddd', color: '#111', height: 90, backgroundColor: '#eee', borderRadius: 45, marginLeft: 'auto', marginRight: 'auto', marginBottom: 30, marginTop: 30, alignItems: 'center', justifyContent: 'center'}}>
          <div style={{textAlign: 'center', fontSize: '14px'}}>VOIDED</div>
        </div>
        <div style={{lineHeight: 1.5, fontSize: '18px'}}>
          Ticket has been voided, hence cannot be used to claim assignment
        </div>
      </div>}
      { activeTicket.holder && activeDriver && !isClosed && <div style={{paddingTop: 0}}>
        <AxlButton circular={true} bg={'periwinkle'} block={true} onClick={() => this.setState({showQR: true})} >Scan</AxlButton>
        <div style={{fontSize: '20px', lineHeight: 1.5, padding: 5, fontWeight: '400'}}>
          Scan to assign and unlock an assignment for driver <strong>{ activeDriver.first_name } { activeDriver.last_name }</strong> [{ activeDriver.id}]
        </div>
      </div>}
      { !isClosed && activeTicket.holder && activeTicket.status !== 'VOIDED' && <div>
        <AxlButton circular={true} bg={'red2'} block={true} onClick={this.onVoidTicket} >Void Ticket</AxlButton>
      </div>}

      { this.state.showQR && <div style={{position: 'absolute', backgroundColor: 'white', top: 10, left: 10, right: 10, bottom :10}}>
        <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '320px', borderRadius: 8, padding: 8}}>
          <QrReader
            delay={this.state.delay}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "100%" }}
          />
        </div>
        <div>
        <AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({showQR: false})}>Close</AxlButton>
        </div>
        <div style={styles.instructions}>
          Scan any QR-Code or BarCode of a shipment label on 
        </div>
      </div> }

      { activeTicket && activeTicket !== 'COMPLETED' && <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20, padding: '15px 0px'}}>
        <div style={{width: 400, height: 200, maxWidth: '100%'}}>
          <TicketMap ticket = {activeTicket} />
        </div>
      </div>}
      <TicketNote ticketId={activeTicket.id} />
      { activeTicket && <History uid={ 'TK_' + activeTicket.id} height={200} /> }
    </div>
  }
}

export default TicketRelease;