import api from './api';
import UserStore from './User';
import FinanceModelStore from './FinanceModel';
import Logger from './Logger'
import AssignmentStore from './Assignment';
import DriverStore from './Driver';
import Websocket from './Websocket';
import DriverPickupTimeStore from './PickupTime';
import TicketStore from './Ticket';
import BookingStore from './Booking';
import EventStore from './Event';
import LocationStore from './Location';

const logger = new Logger();
window.logger = logger;
const ws = new Websocket();
const driverStore = new DriverStore(logger, api);
const locationStore = new LocationStore(logger, api);

export const stores = {
  logger: window.logger,
  financeStore: new FinanceModelStore(logger, api),
  assignmentStore: new AssignmentStore(logger, api, ws),
  userStore: new UserStore(api),
  driverStore,
  locationStore,
  driverPickupStore: new DriverPickupTimeStore(api),
  ticketStore: new TicketStore(logger, api, locationStore),
  bookingStore: new BookingStore(logger, api, ws),
  eventStore: new EventStore(api),
}
