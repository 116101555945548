import { observable, action } from 'mobx';
import api from './api';

class FinanceModelStore {
  constructor(logger, api) {
    this.logger = logger;
    this.api = api;
  }

  @observable model = {};
  @observable models = []
  @observable loadingModel = false;

  @action
  getModel(id) {
    this.loadingModel = true;
    return api.get(`/finance-model/${id}`)
      .then(response => {
        this.loadingModel = false;
        if (response.status === 200) {
          this.model = response.data;
          let promises = this.model.maps.map(this.getMap)
          return Promise.all(promises).then(maps => {
            this.model.region_maps = maps
          })
        } else {
          return {}
        }
      })
  }

  @action
  getModels() {
    this.loadingModel = true;
    return api.get(`/finance-model?limit=100&scope=DRIVER_PAYMENT`)
      .then(response => {
        this.loadingModel = false;
        if (response.status === 200) {
          this.models = response.data;
        } else {
          this.models = []
        }
      })
  }

  getMap(id) {
    return api.get(`/region-map/${id}`)
      .then(response => response.data)
  }

  @action
  updateRegionRate(region, rates) {
    return api.put(`/finance-model/${this.model.id}/rates/${region}`, rates).then((res => {
      this.model = Object.assign(this.model, res.data)
    }))
  }
}

export default FinanceModelStore;
