import React from 'react';
import { layoutTransform, selectedSortSlot, sortSessionSlots } from '../states/SortSession'
import { useRecoilValue, useSetRecoilState} from 'recoil';
import { Tooltip } from "@material-ui/core";

export default function LayoutUnit({unit}) {
  const setSelected = useSetRecoilState(selectedSortSlot)
  const { location } = unit
  const size = (!unit ? null : unit.size ) || { x: 0.0, y: 0.0 }
  const slot = useRecoilValue(sortSessionSlots(unit.id))
  const slotted = slot && slot.objects && slot.objects.length > 0
  const completed = slotted && slot.workload && slot.workload === slot.completed

  const position = location || {x: 0.0, y: 0.0}
  const transform = useRecoilValue(layoutTransform)
  const { scale, flip } = transform
  const transformedPosition = {
    x: flip ? position.y * scale : position.x * scale,
    y: flip ? -position.x * scale : position.y * scale
  }

  const subs = !unit.subs ? null : unit.subs.filter((x) => !x.hidden)
  const hiddens = !unit.subs ? [] : unit.subs.filter((x) => x.hidden)
  const textLoc = hiddens.length > 0 ? (flip ? {x: hiddens[0].location.y, y: -hiddens[0].location.x + unit.size.x - hiddens[0].size.x} : hiddens[0].location) : {x: 0, y: 0}
  const w = flip ? scale * size.y : scale* size.x
  const h = flip ? scale * size.x : scale* size.y
  const offset = flip ? -h : 0
  const showText = w > 15 && h > 15
  if (w < 5 || h < 5) return null;
  const released = slotted && slot.status === 'RELEASED'
  const fillColor = slotted ? (released ? '#eee' : slot.color) : 'white'
  const dim = slotted && slot.dim
  const font = unit.type === 'pallet' ? 'normal 11px sans-serif' : 'bold 14px sans-serif'
  const border = completed ? '#2a4' : unit.type !== 'pallet' ? '#444' : !slotted ? '#888' : slot.status === 'RELEASED' ? '#888' : 'black'
  const borderWidth = completed ? 2 : 1
  const textColor = completed ? '#141' : (slotted || unit.type !== 'pallet') ? '#222' : '#888'
  const progress = slotted ? (slot.completed / slot.workload) : 0
  const scaledHeight = progress * h
  if (released)
  return <React.Fragment>
    <g fill="grey" transform={`translate(${transformedPosition.x} ${transformedPosition.y})`} style={{ opacity: 0.5}}>
      <Tooltip title={ slot.label + ' ' + (slot.workload || '') }>
        <rect y = {offset} width={w} height={h} style={{fill: '#eee', strokeWidth: 1, stroke: '#888'}} />
      </Tooltip>
      { showText && <text style={{font: font, pointerEvents: 'none', fill: '#888', userSelect: 'none'}} x={textLoc.x * scale + 2} y={12+textLoc.y * scale + offset}>{unit.name}</text> }
    </g>
  </React.Fragment>

  if (!unit.points)
    return <React.Fragment>
      <g fill="grey"
        onClick={() => unit.type === 'pallet' && setSelected(slot)}
          transform={`translate(${transformedPosition.x} ${transformedPosition.y})`} style={{cursor: unit.type === 'pallet' ? 'pointer' : 'default', opacity: dim ? 0.25 : 1.0}}>
        <Tooltip title={slotted ? slot.label : ''}>
          <rect y = {offset} width={w} height={h} style={{fill: fillColor, opacity: 0.2, strokeWidth: borderWidth, stroke: border}} />
        </Tooltip>
        <Tooltip title={slotted ? slot.label : ''}>
          <rect y = {offset + h - scaledHeight} width={w} height={scaledHeight} style={{fill: fillColor, strokeWidth: 0, stroke: border}} />
        </Tooltip>
          <rect y = {offset} width={w} height={h} style={{fill: 'none', strokeWidth: borderWidth, stroke: border}} />
          { showText && <text style={{font: font, pointerEvents: 'none', fill: textColor, userSelect: 'none'}} x={textLoc.x * scale + 2} y={12+textLoc.y * scale + offset}>{unit.name}</text> }
        { subs && subs.map(s => <LayoutUnit key={s.id} unit = {s} />) }
      </g>
    </React.Fragment>
  return (<path></path>)
}