import { observable, action } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

class DriverPickupTimeStore {
  constructor(api) {
    this.api = api;
  }

  @observable region = null;
  @observable pickupInfo = {}
  @observable warehouseIds = []

  @action
  selectRegion(region) {
    this.region = region
    this.loadPickupTime()
  }

  @action
  setWarehouses(warehouses) {
    this.warehouseIds = warehouses.map(w => w.id)
    const regions = _.uniq(warehouses.map(w => w.region).filter(r => r))
    this.region = regions.length > 0 ? regions[0] : null
    this.loadPickupTime()
  }

  @action
  loadPickupTime() {
    const s = moment().startOf('day').unix()
    const e = s + 24 * 3600
    this.api.get(`/pickup-slots`, {region: this.region, start: s, end: e}).then((r) => {
      this.pickupInfo = this.process(r.data)
    })
  }

  /*
  @action
  editNote(id, note) {
    return this.api.post(`/pickup-slots/${id}/notes`, {note}).then((r) => {
      if (r.status !== 200) return
      let slots = this.pickupInfo.slots.map(s => {
        if (s.id === id) {
          return Object.assign(s, r.data)
        } else {
          return s
        }
      })
      this.pickupInfo = this.process(Object.assign(this.pickupInfo, {slots}))
    })
  }

  @action
  updateETA(id, eta) {
    return this.api.put(`/pickup-slots/${id}/eta`, eta).then((r) => {
      if (r.status !== 200) {
        alert('Issue while updating eta for pickup time slot')
        return
      }
      let slots = this.pickupInfo.slots.map(s => {
        if (s.id === id) {
          return Object.assign(s, r.data)
        } else {
          return s
        }
      })
      this.pickupInfo = this.process(Object.assign(this.pickupInfo, {slots}))
    })
  }

  @action
  sendMessage(id, note) {
    return this.api.post(`/pickup-slots/${id}/message`, {note}).then((r) => {
      if (r.status !== 200) return
      let slots = this.pickupInfo.slots.map(s => {
        if (s.id === id) {
          return Object.assign(s, r.data)
        } else {
          return s
        }
      })
      this.pickupInfo = this.process(Object.assign(this.pickupInfo, {slots}))
    })
  }
  */

  @action
  process(data) {
    let driverMap = {}

    data.drivers.forEach(d => {
      driverMap[d.id] = d
    })

    data.slots.forEach(s => {
      s.driver = driverMap[s.driver_id]
      s.assignments = data.assignments.filter(a => a.driver_id === s.driver_id)
        .filter(a => a.warehouse_id && this.warehouseIds.indexOf(a.warehouse_id) >= 0)
    })

    const registerdDrivers = _.uniq(data.slots.map(s => s.driver_id))
    const unregistered = data.drivers.filter(d => registerdDrivers.indexOf(d.id) < 0)
      .map(driver => {
        return {
          driver: driver,
          driver_id: driver.id,
          slot_id: 'UNREGISTERED',
          assignments: data.assignments.filter(a => a.driver_id === driver.id)
        }
      })

    const slots = data.slots.concat(unregistered).filter(s => s.assignments.length > 0)
    console.log(slots)
    let groups = _.sortBy(
      _.uniqBy(
        slots.map(
          s => Object.assign({}, {id: s.slot_id, start: s.start, end: s.end})
        ),
        s => s.id
      ),
      s => s.id
    )
    console.log(groups)

    groups.forEach(g => {
      g.slots = slots.filter(s => s.slot_id === g.id)
    })

    data.groups = groups
    return data
  }
}

export default DriverPickupTimeStore
