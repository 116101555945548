export default {
    container: {
        padding: '4px 10px',
        margin: '10px 0px',
        textAlign: 'left',
        backgroundColor: 'white',
        border: 'solid 1px #aaa',
        borderRadius: '3px'
    },
    statusBox: {
        fontFamily: 'AvenirNext-Italic',
        fontSize: '10px',
        color: '#848484',
        fontStyle: 'italic',
        fontWeight: '300'
    },
    status: {
        fontWeight: '400',
        fontSize: '12px',
        float: 'right'
        // color: Colors.maize
    },
    assignButton: {
        position: 'absolute',
        top: '12px',
        right: '12px'
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '60px',
        padding: '8px',
        boxSizing: 'border-box',
        backgroundColor: '#fff'
    },
    highlightCode: {
        backgroundColor: 'rgba(136, 127, 255, 0.5)',
        display: 'inline-block',
        padding: '2px 5px',
        borderRadius: '3px',
        color: '#3b3b3b',
        fontSize: '10px',
    },
    generalInfoTitle: {
        marginBottom: '10px'
    },
    generalInfoValue: {
        marginBottom: '10px'
    },
    label: {
        fontFamily: 'AvenirNext-DemiBold',
        fontWeight: '600',
        marginBottom: '3px',
      fontSize: '14px',
      color: '#444'
    },
    title: {
      fontFamily: 'AvenirNext-DemiBold',
      fontSize: '16px',
      fontWeight: '600',
      color: '#4a4a4a',
      marginBottom: '8px',
      verticalAlign: 'text-top'
    },
    text: {
        fontFamily: 'AvenirNext',
        fontSize: '10px',
        color: '#000000'
    },
    axlModal: {
        width: '1000px',
        height: '800px'
    },
    completeButtons: {
        marginTop: '10px'
    },
    isCompleteButtons: {
        marginTop: '15px',
        // justifyContent: 'space-between'
    },
    claimcode: {
        borderRadius: '5px',
        backgroundColor: 'rgba(136, 127, 255, 0.15)'
    },
    timewindow: {},
    axlAddShipmentModal: {
        width: '650px',
        height: 'auto'
    },
    axlConfirmationModal: {
        height: '200px',
        width: '400px',
        padding: '0px 20px 60px 20px',
        textAlign: 'center'
    },
    box: {
        padding: '10px 7px'
    },
}
