import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { AxlButton, AxlLoading, AxlInput } from 'axl-reactjs-ui';
import styles from './styles'

import BookingSessionInfo from '../../components/BookingSessionInfo/index'
import TicketListContainer from '../../containers/TicketList/index';
import TicketBox from '../../components/TicketBox';
import BookingSessionAssignments from '../../containers/BookingSessionAssignment';
import BookingSessionDrivers from '../../containers/BookingSessionDriver';
import TicketBookContainer from '../../containers/TicketBook';
import { AxlCheckbox } from 'axl-reactjs-ui';

import {
  Route,
  Switch,
  Link,
} from 'react-router-dom'

@inject('bookingStore', 'ticketStore', 'userStore', 'eventStore')
@observer
class BookingSessionScreen extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.loadSession = this.loadSession.bind(this)
    this.gotoTicket = this.gotoTicket.bind(this)
    this.onSelectTicket = this.onSelectTicket.bind(this)
    this.onVoidTicket = this.onVoidTicket.bind(this)
    this.sendPickupTime = this.sendPickupTime.bind(this)
    this.onSendMessage = this.onSendMessage.bind(this)
    this.manuallyAssign = this.manuallyAssign.bind(this)
    this.state = {
      sendingMessage: false,
      toAll: false,
      driverId: null,
      assigning: false,
      showClaimed: true,
      showCompleted: false,
      showBooked: true,
    }
  }

  componentDidMount() {
    this.loadSession()
  }

  loadSession() {
    const { match, bookingStore, eventStore } = this.props
    const { params } = match
    bookingStore.loadSession(params.id)
    eventStore.loadEvents(params.id)  
  }

  goBack() {
    const { history } = this.props
    history.push('/routes')
  }

  manuallyAssign(t, driverId) {
    if (!driverId) return
    if (!t) return
    const { ticketStore, match } = this.props
    const { params } = match
    ticketStore.assignDriver(t.id, driverId, params.id).then((r) => {
      this.setState({
        assigning: false,
        activeTicket: null,
        driverId: null
      })
      this.loadSession();
    })
  }

  onSelectTicket(t) {
    this.setState({activeTicket: t, driverId: null, assigning: false})
    // ticketStore.loadTicket(`${t.holder}@TK_${t.id}`)
  }

  gotoTicket(t) {
    const { history } = this.props
    history.push(`/tickets/${t.holder || 'DR'}@TK_${t.id}`)
  }


  onVoidTicket(id) {
    var r = window.confirm("Please confirm you want to void this ticket!");
    if (r == true) {
      console.log('voiding ...')
      const { ticketStore } = this.props
      ticketStore.voidTicket(id).then((r) => {
        this.loadSession()
        this.setState({activeTicket: null})
      });
    } else  {
      console.log('cancel')
    }
  }

  sendPickupTime() {
    const { bookingStore } = this.props
    bookingStore.sendPickupTime()
  }

  onSendMessage() {
    const { match, bookingStore, eventStore } = this.props
    const { params } = match
    bookingStore.sendMessage(this.state.message, this.state.toAll).then((v) => {
      eventStore.loadEvents(params.id);
    })
    this.setState({sendingMessage: false})
  }

  render() {
    const { bookingStore, userStore } = this.props
    const { activeSession } = bookingStore
    const canMessage = userStore.isAdmin || userStore.isDispatcher
    const canViewDispatch = userStore.isAdmin || userStore.isDispatcher
    const { books, tickets, assignments } = activeSession || {}
    const timezone = assignments == null || assignments.length < 1 ? null : assignments[0].timezone
    let activeBooks = books || []
    let unbooked = !tickets ? [] : tickets.filter(t => !t.holder)

    return <div style={styles.container}>
      {bookingStore.loading && <div style={{position: 'fixed', top: 0, left: 0, right:0, bottom: 0, zIndex: 1000, opacity: 0.5, backgroundColor: '#888', textAlign: 'center', padding: '40px'}}>
        <AxlLoading></AxlLoading>
      </div>}

      <div style={{textAlign: 'left', paddingBottom: 10}}>
        <AxlButton circular={true} compact={true} style={{width: '200px'}} onClick={this.goBack}>Go Back</AxlButton>
        <AxlButton circular={true} bg={'bluish'} compact={true} style={{width: '80px', float: 'right'}} onClick={this.loadSession}>Refresh</AxlButton>
      </div>

      { activeSession && <BookingSessionInfo session={activeSession} canMessage = {canMessage} /> }

      <Switch>
        <Route exact={true} path="/booking-sessions/:id">
          <Fragment>
            { activeSession && activeSession.books.length > 0 && <div style={styles.navigator}>
              <div style={styles.navigator.activeItem}>Tickets</div>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/assignments`}><div style={styles.navigator.inactiveItem}>Assignments</div></Link>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/unbooked`}><div style={styles.navigator.inactiveItem}>Unbooked</div></Link>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/drivers`}><div style={styles.navigator.inactiveItem}>Drivers</div></Link>
            </div> }
            {/* { activeSession && !activeBooks && <TicketListContainer onSelect={ this.onSelectTicket } drivers={activeSession.drivers} tickets={activeSession.tickets} /> } */}
            <div>
            <div style={{display: 'flex'}}>
              <div style={{flex: 1}}>
              <AxlCheckbox theme={`main`} color={`white`} defaultChecked={this.state.showCompleted} onChange={v => this.setState({showCompleted: v.target.checked})} >Completed</AxlCheckbox>
              </div>
              <div style={{flex: 1}}>
                <AxlCheckbox theme={`main`} color={`white`} defaultChecked={this.state.showClaimed} onChange={v => this.setState({showClaimed: v.target.checked})} >Claimed</AxlCheckbox>
              </div>
              <div style={{flex: 1}}>
                <AxlCheckbox theme={`main`} color={`white`} defaultChecked={this.state.showBooked} onChange={v => this.setState({showBooked: v.target.checked})} >Booked</AxlCheckbox>
              </div>
            </div>
            </div>
            { activeSession && activeBooks && activeBooks.map(book => <TicketBookContainer canSMS={canMessage} showClaimed={this.state.showClaimed} showCompleted={this.state.showCompleted} showBooked={this.state.showBooked} canViewDispatch={canViewDispatch} key={book.id} onSelect={ this.onSelectTicket } drivers={activeSession.drivers} book={book} />) }
          </Fragment>
        </Route>
        <Route exact={true} path="/booking-sessions/:id/assignments">
          <Fragment>
            { activeSession && <div style={styles.navigator}>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}`}><div style={styles.navigator.inactiveItem}>Tickets</div></Link>
              <div style={styles.navigator.activeItem}>Assignments</div>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/unbooked`}><div style={styles.navigator.inactiveItem}>Unbooked</div></Link>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/drivers`}><div style={styles.navigator.inactiveItem}>Drivers</div></Link>
            </div> }
            { activeSession && <BookingSessionAssignments session={activeSession} /> }
          </Fragment>
        </Route>
        <Route exact={true} path="/booking-sessions/:id/unbooked">
          <Fragment>
            { activeSession && <div style={styles.navigator}>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}`}><div style={styles.navigator.inactiveItem}>Tickets</div></Link>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/assignments`}><div style={styles.navigator.inactiveItem}>Assignments</div></Link>
              <div style={styles.navigator.activeItem}>Unbooked</div>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/drivers`}><div style={styles.navigator.inactiveItem}>Drivers</div></Link>
            </div> }
            { activeSession && unbooked && <TicketListContainer onSelect={ this.onSelectTicket } drivers={activeSession.drivers} tickets={unbooked} /> }
          </Fragment>
        </Route>
        <Route exact={true} path="/booking-sessions/:id/drivers">
          <Fragment>
            { activeSession && <div style={styles.navigator}>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}`}><div style={styles.navigator.inactiveItem}>Tickets</div></Link>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/assignments`}><div style={styles.navigator.inactiveItem}>Assignments</div></Link>
              <Link to={`/booking-sessions/BS_${activeSession.session.id}/unbooked`}><div style={styles.navigator.inactiveItem}>Unbooked</div></Link>
              <div style={styles.navigator.activeItem}>Drivers</div>
            </div> }
            { activeSession && <BookingSessionDrivers onSelect={ this.onSelectTicket } session={activeSession} /> }
          </Fragment>
        </Route>
      </Switch>
      {/* { activeSession && <TicketListContainer onSelect={ this.onSelectTicket } drivers={activeSession.drivers} tickets={activeSession.tickets} /> } */}

      { this.state.activeTicket && <div style={{position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, paddingTop: '80px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: 'rgba(100, 100, 100, 0.3)'}}>
        <ActiveTicket ticket={this.state.activeTicket} session={activeSession} timezone={timezone}
          onClose={() => this.setState({activeTicket: null})} history={this.props.history}
          onUpdate={() => {
            this.setState({activeTicket: null});
            this.loadSession();
          }}
        />
      </div> }

    </div>
  }
}

@inject('bookingStore', 'ticketStore', 'userStore', 'eventStore')
class ActiveTicket extends Component {
  constructor(props) {
    super(props)
    this.gotoTicket = this.gotoTicket.bind(this)
    this.onVoidTicket = this.onVoidTicket.bind(this)
    this.manuallyAssign = this.manuallyAssign.bind(this)
    this.onUpdatingPickupTime = this.onUpdatingPickupTime.bind(this)
    this.state = {
      driverId: null,
      assigning: false,
    }
  }

  gotoAssignment(ticket) {
    const { userStore } = this.props
    const canViewDispatch = userStore.isAdmin || userStore.isDispatcher
    if (!canViewDispatch) return;
    const { assignment } = ticket
    if (!assignment) return;
    const clients = assignment.client_ids ? assignment.client_ids.join(',') : 'all'
    window.open(`https://dispatch.axlehire.com/routes/today/${assignment.region_code}/${clients}/${assignment.id}`, "_blank")
  }
 

  manuallyAssign(driverId) {
    if (!driverId) return
    const { ticketStore, session, ticket } = this.props
    ticketStore.assignDriver(ticket.id, driverId, session.id).then((r) => {
      this.setState({
        assigning: false,
        driverId: null
      })
      if (this.props.onUpdate) this.props.onUpdate()
    })
  }

  savePickupTime() {
    const { ticketStore, ticket } = this.props
    let startTime = this.state.startTime
    ticketStore.setPickupTime(ticket.id, startTime, startTime + 1800000).then((r) =>{
      if (this.props.onUpdate) this.props.onUpdate()
    })
  }

  gotoTicket(t) {
    const { history } = this.props
    history.push(`/tickets/${t.holder || 'DR'}@TK_${t.id}`)
  }


  onVoidTicket(id) {
    var r = window.confirm("Please confirm you want to void this ticket!");
    if (r == true) {
      console.log('voiding ...')
      const { ticketStore } = this.props
      ticketStore.voidTicket(id).then((r) => {
        if (this.props.onUpdate) this.props.onUpdate()
      });
    } else  {
      console.log('cancel')
    }
  }

  onUpdatingPickupTime() {
    const { ticketStore, timezone } = this.props
    var options = ticketStore.availablePickupTimes(timezone)
    if (!options || options.length < 1) {
      alert('Too late to set picku time')
      return
    }
    this.setState({pickup_options: options, setting_pickup: true, startTime: options[0].start})
  }

  render() {
    const { ticket, canViewDispatch } = this.props
    if (!ticket) return <div></div>
    const isOpen = !ticket.status || ticket.status === 'PENDING'  || ticket.status === 'IN_PROGRESS' || ticket.status === 'READY'
    return <div style={{backgroundColor: 'white', borderRadius: 10, padding: 10, boxShadow: '0px 1px 1px #444', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto'}}>
      <TicketBox ticket = {ticket} />
      <div>{ticket.status}</div>
      { ticket.holder && isOpen && !this.state.setting_pickup && <div>
        <AxlButton block={true} circular={true} bg={'red2'} onClick={() => this.onVoidTicket(ticket.id)}>VOID TICKET</AxlButton>
      </div> }
      { ticket.holder && isOpen && this.state.setting_pickup && <div>
        <div>CHOOSE A PICKUP TIME</div>
        <div>
          <select value={this.state.startTime} onChange={(e) => this.setState({startTime: parseInt(e.target.value)})}>
            {this.state.pickup_options.map((opt) => <option key={opt.start} value={opt.start}>{opt.name}</option>)}
          </select>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}><AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({setting_pickup: false})}>CANCEL</AxlButton></div>
          <div style={{flex: 1}}><AxlButton block={true} circular={true} bg={'bluish'} onClick={() => this.savePickupTime()}>SAVE</AxlButton></div>
        </div>
      </div> }
      { ticket.holder && isOpen &&  !this.state.setting_pickup && !this.state.assigning &&  <div>
        <AxlButton block={true} circular={true} bg={'black'} onClick={ () => this.onUpdatingPickupTime() }>UPDATE PICKUP TIME</AxlButton>
      </div> }
      { !ticket.holder && <div>
        { !this.state.assigning && <AxlButton block={true} circular={true} bg={'red2'} onClick={() => this.setState({assigning: true})}>ASSIGN DRIVER</AxlButton> }
        { this.state.assigning && <div>
          <div>
            <AxlInput type={'number'} onChange={(v) => this.setState({driverId: v.target.value}) } />
          </div>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}><AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({assigning: false})}>CANCEL</AxlButton></div>
            <div style={{flex: 1}}><AxlButton block={true} circular={true} bg={'bluish'} onClick={() => this.manuallyAssign(this.state.driverId)}>SAVE</AxlButton></div>
          </div>
        </div>}
      </div> }
      {ticket.assignment && canViewDispatch && !this.state.setting_pickup && !this.state.assigning && <div>
        <AxlButton block={true} circular={true} bg={'black'} onClick={() => this.gotoAssignment(ticket)}>OPEN ASSIGNMENT</AxlButton>
      </div> }
      { !this.state.setting_pickup && !this.state.assigning && <div>
        <AxlButton block={true} circular={true} bg={'bluish'} onClick={() => this.gotoTicket(ticket)}>OPEN TICKET</AxlButton>
      </div> }
      <div>
        <AxlButton block={true} circular={true} onClick={() => this.props.onClose && this.props.onClose() }>Close</AxlButton>
      </div>
      <div style={{padding: 5, fontSize: '0.8em', color: '#888'}}>
        { ticket.id }
      </div>
    </div>
  }
}

export default BookingSessionScreen
