export default {
  book: {
    backgroundColor: 'white',
    borderRadius: '8px',
    margin: 10,
    paddingTop: 8,
    paddingBottom: 8,
    boxShadow: '0px 0px 2px #888',
    name: {
      padding: '8px',
      borderBottom: 'solid 1px #eee',
      backgroundColor: '#efefef',
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    }
  },
  assignment: {
    display: 'flex',
    padding: '6px',
    borderBottom: 'solid 1px #eee'
  }
}
