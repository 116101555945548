import React, { Component } from 'react';
import styles from './styles';
// import DriverInfoCard from '../../components/DriverInfoCard/index';
import Moment from 'react-moment';
import { AxlButton, AxlModal, AxlPopConfirm, STATUS_COLOR_CODE } from 'axl-reactjs-ui';
import { inject, observer } from 'mobx-react';
import StopListContainer from '../StopList/index'


@inject('assignmentStore', 'userStore' )
@observer
class AssignmentDetail extends Component {
  constructor(props) {
    super(props)
    this.onRelease = this.onRelease.bind(this)
    this.onReleasePartial = this.onReleasePartial.bind(this)
    this.selectStop = this.selectStop.bind(this)
    this.markOutboundFailed = this.markOutboundFailed.bind(this)
    this.notifyReady = this.notifyReady.bind(this)
    this.state = {

    }
  }

  onRelease() {
    const { assignmentStore, history } = this.props;
    const { activeAssignment } = assignmentStore
    if (!activeAssignment) return
    const { pickup_pending, inbound_unscanned } = activeAssignment
    if (pickup_pending.length > 0 || inbound_unscanned.length > 0) {
      window.alert('Cannot release before PICKING UP everything')
      return
    }
    assignmentStore.releaseAssignment().then((r) => {
      console.log(r)
      if (r.status === 204)
        history.push('/routes')
      else {
        window.alert('Error while releasing Assignment')
      }
    })
  }

  onReleasePartial() {
    const { assignmentStore, history } = this.props;
    const { activeAssignment } = assignmentStore
    if (!activeAssignment) return
    const { pickup_succeeded } = activeAssignment
    if (pickup_succeeded.length < 1) {
      window.alert('No boxes picked up yet!')
      return
    }
    const shipments = pickup_succeeded.map(s => s.shipment_id)
    assignmentStore.releasePartialAssignment(shipments).then((r) => {
      console.log(r)
      if (r.status === 200)
        history.push('/routes')
      else {
        window.alert('Error while releasing Assignment')
      }
    })
  }

  selectStop(stop) {
    this.setState({selectedStop: stop})
  }

  markOutboundFailed(reason) { // reason: missing / damaged / leaking
    const { assignmentStore } = this.props;
    if (!this.state.selectedStop) return;
    assignmentStore.markOutboundFailed(this.state.selectedStop.shipment, reason).then((s) => {
      this.setState({selectedStop: null})
    })
  }

  resetPickup() { // reason: missing / damaged / leaking
    const { assignmentStore } = this.props;
    if (!this.state.selectedStop) return;
    assignmentStore.resetPickup(this.state.selectedStop).then((s) => {
      this.setState({selectedStop: null})
    })
  }

  removeFromRoute() { // reason: missing / damaged / leaking
    const { assignmentStore } = this.props;
    if (!this.state.selectedStop) return;
    assignmentStore.removeFromRoute(this.state.selectedStop).then((s) => {
      this.setState({selectedStop: null})
    })
  }

  gotoDriver(driver) {
    const { history } = this.props
    history.push(`/drivers/${driver}`)
  }

  notifyReady() {
    const { assignmentStore } = this.props
    assignmentStore.notifyReady()
  }

  render() {
    const { assignmentStore} = this.props;
    const { activeAssignment } = assignmentStore
    const { selectedStop } = this.state

    if (!activeAssignment) return <div></div>

    const { assignmentLabel, assignment, driver, inbound_failed, inbound_unscanned, pickup_failed, pickup_succeeded, pickup_pending, completed, assignmentHistory } = activeAssignment
    // const isCompleted = assignment && assignment.status === 'COMPLETED'
    // const isInprogress = assignment && assignment.status === 'IN_PROGRESS'
    // const isActivated = assignment && assignment.driver_id && assignment.is_active
    // const isPending = assignment && assignment.driver_id && !assignment.is_active
    const statusColor = assignment.status ? STATUS_COLOR_CODE[assignment.status] : STATUS_COLOR_CODE['PENDING']
    const histories = assignmentHistory ? assignmentHistory.filter(x => x.action === 'release') : []
    const released = histories.length > 0 ? histories[0] : null
    const notifyEvents = assignmentHistory ? assignmentHistory.filter(x => x.action === 'notify-ready') : []
    const canRelease = pickup_pending.length < 1 && inbound_unscanned.length < 1
    const canReleasePartial = pickup_succeeded.length > 0 && (pickup_pending.length > 0 || inbound_unscanned.length > 0)
    const canNotifyReady = driver !== null && (!pickup_succeeded || pickup_succeeded.length < 1 ) && assignment.status != 'COMPLETED'

    return <div>
      <div style={styles.container}>
        <div style={styles.title}>
            {assignment && <code style={styles.highlightCode}>{assignment.id}</code>} {assignmentLabel ? assignmentLabel.prefix : ''}
            <span style={{...styles.status, color: statusColor}}>{assignment.status}</span>
        </div>
        <div style={{marginBottom: '10px'}}>
          <span style={{marginRight: '12px'}}><b>{ assignment.shipment_count }</b> Shipments</span>
          <span><b> { inbound_failed.length }</b> Missing </span>
          <span> <b> { inbound_unscanned.length }</b> Unconfirmed </span>
        </div>
        <div style={{fontSize: '14px', marginBottom: '8px'}}>
          Pickup window: <b><Moment format={'hh:mm A'}>{assignment.predicted_start_ts}</Moment> - <Moment format={'hh:mm A'}>{assignment.predicted_latest_start_ts}</Moment></b>
        </div>
        { !driver && <div><b>NOT ASSIGNED</b></div> }
        { driver && <div style={{display: 'flex', borderTop: 'solid 1px #eee', paddingTop: '8px', cursor: 'pointer'}} onClick={ () => this.gotoDriver(driver.id) }>
          { driver.photo && <div>
            <img src={driver.photo} alt={'driver'} style={{maxWidth: '64px', maxHeight: '48px', borderRadius: '3px'}} />
          </div> }
          <div style={{padding: '0px 10px', lineHeight: '24px'}}>
            <div><b><code>[{driver.id}]</code> { driver.first_name } {driver.last_name}</b></div>
            <div>{driver.phone_number}</div>
          </div>
        </div>}
        { canNotifyReady && <div  style={{marginTop: '8px', color: '#888', marginBottom: '0px', borderTop: 'solid 1px #eee', paddingTop: '8px', fontSize: '0.9em'}}>
          <AxlButton compact={true} bg={'red'} onClick={this.notifyReady}>Notify Pickup ready</AxlButton>
          Let driver know to come and pickup route.
        </div> }
        { notifyEvents.map((e) => <div key={e.id} style={{marginTop: '4px', marginBottom: '4px', paddingTop: '8px', fontSize: '0.9em'}}>
          Notified Pickup ready by <b>{e.subject.attributes.name}</b> at <b><Moment format={'hh:mm A'}>{e.ts}</Moment></b>
        </div>)}

        { released && <div style={{marginTop: '8px', marginBottom: '8px', borderTop: 'solid 1px #eee', paddingTop: '8px'}}>
          Released by <b>{released.username}</b> @ <b><Moment format={'hh:mm A'}>{released.timestamp}</Moment></b>
        </div>}

      </div>

      {canRelease && <div>
        <AxlPopConfirm
          style={{padding: '0px'}}
          trigger={<AxlButton style={{width: '240px'}} bg={'bluish'}>RELEASE</AxlButton>}
          titleFormat={<div>{`Release`}</div>}
          textFormat={<div>{`Please confirm you want to release the selected Assignment!`}</div>}
          okText={`YES`}
          onOk={this.onReleasePartial}
          onCancel={() => console.log('Cancel')} />
      </div>}

      {canReleasePartial && <div>
        <AxlPopConfirm
          style={{padding: '0px'}}
          trigger={<AxlButton style={{width: '240px'}} bg={'red'}>PARTIAL RELEASE</AxlButton>}
          titleFormat={<div>{`Release Part of the Route`}</div>}
          textFormat={<div>{`Please confirm you want to release part of the selected Assignment. Assignment will be split up. Driver will need to go back later to take the rest. Please make sure drivers only take boxes which have been scanned already.`}</div>}
          okText={`YES`}
          onOk={this.onReleasePartial}
          onCancel={() => console.log('Cancel')} />
      </div>}

      <StopListContainer title={'INBOUND FAILED'} stops={inbound_failed} />
      <StopListContainer onSelect={this.selectStop} title={'INBOUND UN-CONFIRMED'} stops={inbound_unscanned} />
      <StopListContainer onSelect={this.selectStop} title={'pickup pending'} stops={pickup_pending} />
      <StopListContainer title={'pickup failed'} stops={pickup_failed} />
      <StopListContainer onSelect={this.selectStop} title={'PICKUP SUCCEEDED'} stops={pickup_succeeded} />
      <StopListContainer title={'COMPLETED'} stops={completed} />

      { selectedStop && <AxlModal style={{width: '320px', maxWidth: 'calc(100%-20px)'}} onClose={() => this.setState({selectedStop: null})}>
        <div style={{textAlign: 'left', padding: '16px', lineHeight: '22px', fontSize: '15px'}}>
          <div><span style={styles.label}>Shipment ID:</span> <code>{selectedStop.shipment.id}</code></div>
          <div><span style={styles.label}>Client:</span> <strong>{selectedStop.client.company}</strong></div>
          <div><span style={styles.label}>Inbound Status:</span> <strong>{selectedStop.shipment.inbound_status}</strong></div>
          <div><span style={styles.label}>Customer:</span> <strong>{selectedStop.shipment.customer.name}</strong></div>
          <div>{selectedStop.shipment.dropoff_address.street}</div>
          <div>{selectedStop.shipment.dropoff_address.city}, {selectedStop.shipment.dropoff_address.state} {selectedStop.shipment.dropoff_address.zipcode}</div>
          <div style={{textAlign: 'center'}}>
            { selectedStop.corresponding_stop && selectedStop.corresponding_stop.status !== 'SUCCEEDED' && (!selectedStop.corresponding_stop.delivery_status || !selectedStop.corresponding_stop.delivery_status['receive-status']) && <div style={{margin: '6px'}}>
              <AxlPopConfirm
                trigger={<AxlButton compact={true} bg={'red'} style={{width: '180px'}}>{ selectedStop.shipment.inbound_status === 'RECEIVED_OK' ? 'MIS-SORTED' : 'MISSING' }</AxlButton>}
                titleFormat={<div>{`Mark MISSING`}</div>}
                textFormat={<div>{`Please confirm you want to mark this deliver as ${selectedStop.shipment.inbound_status === 'RECEIVED_OK' ? 'MIS-SORTED' : 'MISSING'}!`}</div>}
                okText={`YES`}
                onOk={() => this.markOutboundFailed('missing')}
                cancelText={`NO`}
                onCancel={() => console.log('Activate')} /> 
            </div> }
            { selectedStop.corresponding_stop && (selectedStop.corresponding_stop.status === 'SUCCEEDED' || (selectedStop.corresponding_stop.delivery_status && selectedStop.corresponding_stop.delivery_status['receive-status'] === 'ok')) && <div style={{margin: '6px'}}>
              <AxlPopConfirm
                trigger={<AxlButton compact={true} bg={'gray'} style={{width: '180px'}}>RESET</AxlButton>}
                titleFormat={<div>{`Reset Pickup`}</div>}
                textFormat={<div>{`Please confirm you want to reset pickup status for this deliver as 'PENDING'!`}</div>}
                okText={`YES`}
                onOk={() => this.resetPickup()}
                cancelText={`NO`}
                onCancel={() => console.log('Activate')} /> 
            </div> }
            <div style={{margin: '6px'}}>
              <AxlPopConfirm
                trigger={<AxlButton compact={true} bg={'maize'} style={{width: '180px'}}>DAMAGED</AxlButton>}
                titleFormat={<div>{`Mark DAMAGED`}</div>}
                textFormat={<div>{`Please confirm you want to mark this deliver as DAMAGED!`}</div>}
                okText={`YES`}
                onOk={() => this.markOutboundFailed('damaged')}
                cancelText={`NO`}
                onCancel={() => console.log('Activate')} />
            </div>
            {/* <div style={{marginTop: '6px'}}>
              <AxlPopConfirm
                trigger={<AxlButton compact={true} bg={'periwinkle'} style={{width: '180px'}}>LEAKING</AxlButton>}
                titleFormat={<div>{`Mark LEAKING`}</div>}
                textFormat={<div>{`Please confirm you want to mark this deliver as LEAKING!`}</div>}
                okText={`YES`}
                onOk={() => this.markOutboundFailed('leaking')}
                cancelText={`NO`}
                onCancel={() => console.log('Activate')} />
            </div> */}
            { selectedStop.corresponding_stop && (selectedStop.corresponding_stop || selectedStop.corresponding_stop.status === 'PENDING') && <div style={{marginTop: '6px'}}>
              <AxlPopConfirm
                trigger={<AxlButton compact={true} bg={'gray'} style={{width: '180px'}}>REMOVE FROM ROUTE</AxlButton>}
                titleFormat={<div>{`REMOVE DELIVERY`}</div>}
                textFormat={<div>{`Please confirm you want to remove this deliver from current Assignment!`}</div>}
                okText={`YES`}
                onOk={() => this.removeFromRoute()}
                cancelText={`NO`}
                onCancel={() => console.log('Remove')} />
            </div> }
          </div>
        </div>
        <div  style={{marginBottom: '6px'}}>
        <AxlButton compact={true} bg={'none'} style={{width: '180px'}} onClick={() => this.setState({selectedStop: null})}>CLOSE</AxlButton>
        </div>
      </AxlModal> }

    </div>
  }
}

export default AssignmentDetail
