export default {
  list_title: {
      textAlign: 'center',
      padding: '0px 10px 0 10px',
      fontWeight: 'bold',
      fontSize: '13px'
  },
  header_bar: {
    flex: 1,
    height: '1px',
    backgroundColor: '#aaa'
  },
  list_header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '15px'
  }
}
