import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx'
import { AxlButton, AxlInput } from 'axl-reactjs-ui';
import styles from './styles'
import QrReader from "react-qr-reader";
import AssignmentMiniBox from '../../components/AssignmentMiniBox/index'
import { Link } from 'react-router-dom';

@inject('assignmentStore', 'userStore', 'bookingStore')
@observer
class RouteControl extends Component {
  constructor(props) {
    super(props)
    this.onNewAssignmentId = this.onNewAssignmentId.bind(this)
    this.onSelectAssignment = this.onSelectAssignment.bind(this)
    this.handleScan = this.handleScan.bind(this)
    this.handleError = this.handleError.bind(this)
    this.onOpenAssignment = this.onOpenAssignment.bind(this)
    this.openTicket = this.openTicket.bind(this)
    this.togglePanel = this.togglePanel.bind(this)
    this.setRegion = this.setRegion.bind(this)
    this.gotoPickup = this.gotoPickup.bind(this)
    this.state = {
      delay: 300,
      result: "No result",
      showQR: false,
      showPanel: true,
      region: 'All'
    };
    const { assignmentStore, userStore } = this.props
    assignmentStore.setWarehousesFilter(userStore.warehouses.map(w => w.id));
    this.disposer = autorun( () => {
      assignmentStore.setWarehousesFilter(userStore.warehouses.map(w => w.id));
    } );
    setTimeout(() => {
      this.handleScan(null)
    }, 3000)
  }

  componentDidMount() {
    const { bookingStore } = this.props
    bookingStore.loadActiveSessions()
  }

  gotoPickup() {
    const { history } = this.props
    history.push('/pickup')
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  togglePanel() {
    this.setState({showPanel: !this.state.showPanel})
  }

  onNewAssignmentId(v) {
    this.setState({assignmentId: parseInt(v.target.value)})
  }

  onSelectAssignment() {
    const { history } = this.props
    if (!this.state.assignmentId) return
    history.push(`/routes/${this.state.assignmentId}`)
  }

  onOpenAssignment(v) {
    const { history } = this.props
    history.push(`/routes/${v}`)
  }

  openTicket(v) {
    const { history } = this.props
    history.push(`/tickets/${v}`)
  }

  handleScan(v) {
    const { assignmentStore } = this.props
    if (!v) return;
    console.log(v)
    if (v.indexOf('DR_') >= 0 && v.indexOf('@TK_') > 0) {
      this.openTicket(v)
      return
    }
    if (v && v.length < 8 && !isNaN(v)) {
      this.setState({showQR: false})
      assignmentStore.loadAssignmentFromShipment(v).then(assignmentId => {
        if (assignmentId) {
          this.setState({assignmentId})
          this.onSelectAssignment()
        }
      })
    }
    else if (v.length < 64) {
      this.setState({showQR: false})
      assignmentStore.loadAssignmentFromInternalId(v).then(assignmentId => {
        if (!assignmentId) return;
        this.setState({assignmentId})
        this.onSelectAssignment()
      })
    }
  }

  handleError(v) {
    console.log(v)
  }

  setRegion(region) {
    const { assignmentStore } = this.props
    assignmentStore.setRegionFilter(region)
  }

  setWarehouse(warehouse) {
    const { assignmentStore } = this.props
    assignmentStore.setWarehouseFilter(warehouse)
  }

  render() {
    const { assignmentStore, bookingStore } = this.props
    const { activeAssignments } = assignmentStore
    const { activeSessions } = bookingStore
    // const regions = ['All Regions', 'SFO', 'LAX', 'SDLAX', 'SEA', 'PDX']
    // const regionOptions = regions.map(r => Object.assign({}, {
    //   title: r,
    //   action: () => this.setRegion(r)
    // }))
    return <div style={styles.container}>
      <div style={styles.toggle_panel_btn} onClick={this.togglePanel}></div>
      {/* <div style={styles.region_btn_container}>
        <AxlSimpleDropDown anchor={'right'} style={{zIndex: 10000, width: '160px'}} items={regionOptions}><div style={styles.region_btn}>{selectedRegion}</div></AxlSimpleDropDown>
      </div> */}
      <div style={{display: this.state.showPanel ? 'block' : 'none'}}>
      <div style={{display: 'none', justifyContent: 'center'}}>
        <AxlInput style={{width: '200px'}} type={"number"} placeholder={'Enter assignment Id'} onChange={(v) => this.onNewAssignmentId(v)} onEnter = {this.onSelectAssignment} />
        <AxlButton style={{width: '100px', height: '32px'}} compact={true} onClick={this.onSelectAssignment}>Search</AxlButton>
      </div>
      <div>
        <div style={{marginBottom: '8px', paddingTop: '8px'}}>
          {this.state.showQR && <AxlButton compact={true} bg={'bluish'} style={{width: '160px'}} onClick={() => this.setState({showQR: false})}>CLOSE</AxlButton> }
          {!this.state.showQR && <AxlButton compact={true} bg={'red'} style={{width: '160px'}} onClick={() => this.setState({showQR: true})}>SCAN</AxlButton> }

          <AxlButton compact={true} bg={'gray'} style={{width: '160px'}} onClick={ this.gotoPickup }>Pickup Time</AxlButton>
        </div>

        { this.state.showQR && <QrReader
          delay={this.state.delay}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: "100%", maxWidth: '600px' }}
        /> }
      </div>
      {activeSessions && activeSessions.length > 0 && <div style={{marginBottom: 20}}>
        <h4 style={{paddingBottom: 5, marginBottom: 5}}>Ticket Booking Sessions</h4>
        { activeSessions.map(s => <div key={s.id}>
          <Link to={`/booking-sessions/BS_${s.id}`}>{ s.name }</Link>
        </div>)}
      </div>}
      </div>
      { activeAssignments && activeAssignments.length > 0 && <div>
        <div>PICKING UP NOW</div>
        { activeAssignments.map(a => <AssignmentMiniBox key={a.assignment.id} detail={a} onSelect={() => this.onOpenAssignment(a.assignment.id)} />)}
      </div> }
    </div>
  }
}

export default RouteControl
