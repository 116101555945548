import _ from 'lodash'
import { SlotColors } from '../constants/Color';
import { sortLayoutAPI } from '../repositories/SortLayout';

const { atom, selector, selectorFamily, atomFamily, useRecoilCallback } = require("recoil");

export const selectedSessionId = atom({
  key: `sort-selected-layout-session-id`,
  default: null
})

export const selectedSession = atom({
  key: `sort-selected-layout-session`,
  default: null
})

export const sortSessionSlotAtoms = atomFamily({
  key: `sort-session-slot-atom`
})

export const sortCenterId = atom({
  key: `sort-center-id`,
  default: null
})

export const sortCenter = selector({
  key: `sort-center`,
  get: async ({get}) => {
    const id = get(sortCenterId)
    // if (!id) return null
    const center = await sortLayoutAPI.loadCenter(id)
    return center
  }
})

export const loadTime = atom({
  key: `data-load-time`
})

export const sortCenterActiveSessions = selector({
  key: `sort-center-sessions`,
  get: async ({get}) => {
    const id = get(sortCenterId)
    const loadedTime = get(loadTime)
    // if (!id) return null
    const sessions = await sortLayoutAPI.loadActiveSessionsByCenter(id, loadedTime)
    return sessions
  }
})

export const sortSessionSlots = selectorFamily({
  key: `sort-session-slot-selector`,
  get: (id) => ({get}) => {
    const activeSession = get(selectedSession)
    if (!activeSession) return null;
    const slot = get(sortSessionSlotAtoms(id))
    if (!slot) return slot
    const hilighted = get(hilightedZones)
    const searchText = get(searchSlotLabel)
    let updated = _.cloneDeep(slot)
    const { label, zone } = slot
    const z = zone || (label ? label.split('-')[0] : '')
    const slotColor = get(sortSlotColor(z))
    updated.dim = (hilighted.length > 0 && hilighted.indexOf(z) < 0) || (searchText && (!label || label.indexOf(searchText) < 0))
    
    if (updated.dim && searchText && searchText.trim() !== '' && slot.objects && slot.objects.includes("AS_" + searchText)) {
      updated.dim = false
    }

    if (updated.dim && searchText && searchText.trim() !== '' && slot.items && slot.items.includes("SH_" + searchText)) {
      updated.dim = false
    }

    updated.color = slot.status === 'DISCARDED' ? '#333' : slotColor
    return updated
  }
})

export const sortSessionPods = atom({
  key: `sort-session-pods-selector`,
})

export const zoneColorMap = atom({
  key: 'zone-color-map'
})

export const sortSlotColor = selectorFamily({
  key: 'slot-color-selector',
  get: (zone) => ({get}) => {
    const colorMap = get(zoneColorMap)
    if (!colorMap) return '#eee'
    const color = colorMap.map[zone]
    return color || '#eee'
  }
})

export function useSortSession() {
  const callback = useRecoilCallback(({snapshot, set, reset}) => async (id) => {
    set(selectedSessionId, id)
    if (!id) {
      set(selectedSession, null)
      // reset(sortSessionSlotAtoms)
      reset(zoneColorMap)
      return
    }
    if (id) {
      const session = await sortLayoutAPI.loadSession(id)
      set(selectedSession, session)
      const slots = await sortLayoutAPI.loadSessionSlots(id)
      let slotMap = {}
      let groups = []
      slots.forEach(slot => {
        set(sortSessionSlotAtoms(slot.unit_id), slot)
        const gg = slot.ancestors.filter(a => a.id.indexOf("G") == 0)
        if (gg.length > 0) {
          const ee = groups.filter(g => g.id === gg[0].id)
          if (ee.length < 1) {
            groups.push(gg[0])
          }
        }
        slotMap[slot.unit_id] = slot
      })
      let zones = []
      slots.filter(s => s.objects && s.objects.length > 0).forEach(s => {
        const z = s.zone || (s.label ? s.label.split('-')[0] : '')
        if (z) {
          if (zones.indexOf(z) < 0) zones.push(z)
        }
      });  
      let zoneColors = {}
      for (var i = 0; i < zones.length; i++) {
        zoneColors[zones[i]] = SlotColors[i % SlotColors.length]
      }
      set(zoneColorMap, { map: zoneColors, zones })

      let pods = groups.map(p => {
        const ss = slots.filter(s => s.unit_id.indexOf(p.id + ":") == 0)
        return Object.assign({}, {
          id: p.id,
          name: p.name,
          workload: _.sum(ss.map(s => s ? (s.workload || 0) : 0)),
          completed: _.sum(ss.map(s => s ? (s.completed || 0) : 0)),
        })
      })
      const sessionPods = {
        workload: _.sum(pods.map(p => p.workload)),
        completed: _.sum(pods.map(p => p.completed)),
        pods: pods.filter(p => p.workload > 0)
      }
      set(sortSessionPods, sessionPods)
    }
  });
  return callback;
}

export const useAssignSortSlot = () => useRecoilCallback(({snapshot, set}) => async (slot) => {
  const r = await sortLayoutAPI.assignSlot(slot.id, slot.assignments, slot.zone)
  const updatedSlot = r.data
  set(sortSessionSlotAtoms(updatedSlot.unit_id), updatedSlot)
  set(selectedSortSlot, null)
  const colorMap = await snapshot.getPromise(zoneColorMap)
  const z = updatedSlot.zone
  if (!z || colorMap.map[z]) return;
  const map = _.cloneDeep(colorMap)
  map.map[z] = SlotColors[Object.getOwnPropertyNames(map.map).length % SlotColors.length]
  map.zones.push(z)
  set(zoneColorMap, map)
});

export const useUnassignSortSlot = () => useRecoilCallback(({snapshot, set}) => async (slot) => {
  await sortLayoutAPI.unassignSlot(slot.id)
  const u = _.cloneDeep(slot)
  u.objects = null
  u.label = null
  set(sortSessionSlotAtoms(slot.unit_id), u)
  set(selectedSortSlot, null)
});

export const layoutTransform = atom({
  key: `sort-layout-scale`,
  default: {
    scale: 7.0,
    x: 0.0,
    y: 0.0,
    flip: false,
    advanced: true
  }
})

export const hilightedZones = atom({
  key: `sort-hilighted-zones`,
  default: []
})

export const searchSlotLabel = atom({
  key: `sort-search-slot-label`,
  default: ''
})

export const selectedSortSlot = atom({
  key: `sort-selected-slot`
})
