import React from 'react';
import {useRecoilState } from 'recoil';
import { hilightedZones } from '../states/SortSession'
import { Checkbox, Container } from "@material-ui/core";


export default function SortZones({colorMap}) {
  const [ hilighted, setHilighted ] = useRecoilState(hilightedZones)
  const { zones, map } = colorMap

  const toggleHiglight = (code) => {
    setHilighted((v) => {
      if (v.indexOf(code) >= 0) {
        return v.filter(e => e !== code)
      } else {
        return [code].concat(v)
      }
    });
  }
  const setHiglight = (code) => {
    setHilighted([code]);
  }

  return <Container style={{padding: 0}}>
    { zones.map(m => <div key={m} style={{textAlign: 'left', padding: 0, margin: 0}}>
      <Checkbox size='small' checked={hilighted.indexOf(m) >= 0} onClick={ () => toggleHiglight(m) } />
      <span onClick={() => setHiglight(m)} style={{backgroundColor: map[m], cursor: 'pointer', width: 10, height: 10, display: 'inline-block', marginRight: 8}}></span>
      <span style={{cursor: 'pointer'}} onClick={() => setHiglight(m)}>{m}</span>
    </div>)}
  </Container>
}