import React, { Component } from 'react';
import { AxlMiniStopBox } from 'axl-reactjs-ui';
import styles from './styles'

class StopListContainer extends Component {
  selectStop(stop) {
    return this.props.onSelect && this.props.onSelect(stop)
  }

  render() {
    return <div style={styles.list} className={'momentumScroll'}>
      <div style={styles.list_header}>
        <div style={styles.header_bar}></div>
        <div style={styles.list_title}>{this.props.title.toUpperCase()} ({this.props.stops.length})</div>
        <div style={styles.header_bar}></div>
      </div>

      { this.props.stops.map((stop) => <AxlMiniStopBox onClick={() => this.selectStop(stop)}
          key={stop.id}
          stop={stop} />) }
    </div>
  }
}

export default StopListContainer
