export const SlotColors = [
  '#00b4d8',
  '#ffadad',
  '#7CEB00',
  '#caffbf',
  '#ffd6a5',
  '#10989E',
  '#ffc6ff',
  '#fdffb6',
  '#6890F2',
  '#B4F250',
  '#F25938',
  '#ade8f4',
  '#26A6A6',
  '#F563CD',
  '#50F2F2',
  '#F2D438',
  '#A6932E',
  '#a8dadc',
  '#F56362',
  '#50B1F2',
  '#F2F138',
  '#A6A52E'
]