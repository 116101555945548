import React from 'react';
import {useRecoilState } from 'recoil';
import { searchSlotLabel } from '../states/SortSession'
import { Container, Input } from "@material-ui/core";


export default function SortSlotSearch({zones}) {
  const [ searchText, setSearchText ] = useRecoilState(searchSlotLabel)
  return <Container style={{padding: 5}}>
    <Input placeholder='Search' value={searchText} onChange = {(e) => setSearchText(e.target.value.toUpperCase())} />
  </Container>
}