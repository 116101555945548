import React, { useState } from 'react';
import { selectedSortSlot, useAssignSortSlot, useUnassignSortSlot } from '../states/SortSession'
import { useRecoilState } from 'recoil';
import { Button, Container, Input, Typography } from '@material-ui/core';

export default function SelectedSlot() {
  const [slot, setSelectedSlot] = useRecoilState(selectedSortSlot)
  const [ assignmentId, setAssignmentId ] = useState(null)
  const [ zone, setZone ] = useState('')

  const assignSortSlot = useAssignSortSlot()

  const assignSlot = () => {
    assignSortSlot({
      id: slot.id,
      assignments: [parseInt(assignmentId)],
      zone
    })
  }

  const unassign = useUnassignSortSlot()
  const unassignSlot = () => unassign(slot)

  if (!slot) return <React.Fragment></React.Fragment>

  // const { slot } = selectedSlot

  const slotted = slot && slot.objects && slot.objects.length > 0
  const fillColor = slotted ? slot.color : '#efefef'
  return <Container style={{position: 'fixed', top: '50%', left: '50%', marginLeft: -150, marginTop: -100, width: 300, height: 200, backgroundColor: 'white', borderRadius: 4, boxShadow: '0px 1px 2px #444'}}>
    <div style={{marginTop: 5}}>{ slot.ancestors.map(a => <span key={a.id} style={{display: 'inline-block', padding: 4, margin: 2, borderRadius: 2, backgroundColor: '#f0f0f0'}}>{a.name} </span>) }</div>
    <div style={{ color: '#efefef', fontSize: 11 }}>{ slot.id }</div>
    { slotted && <div>
      <Typography style={{marginTop: 15}}>{ slot.completed || 0 } / {slot.workload} packages</Typography>
      <Container style={{marginTop: 5, padding: 10, borderRadius: 5, backgroundColor: fillColor}}>
        <code style={{color: '#222', fontSize: 20}}>{ slot.label }</code>
      </Container>
      <Container>
        { slot.objects.map(o => <code style={{marginLeft: 10, marginRight: 10, color: '#444', fontSize: 13}}> { o.split('_')[1]} </code>) }
      </Container>
    </div>}
    { !slotted && <React.Fragment>
    <div style={{marginTop: 10}}>
      <Input placeholder='Zone' onChange={(e) => setZone(e.target.value)} />
    </div>
    <div style={{marginTop: 10}}>
      <Input placeholder='assignment Id' onChange={(e) => setAssignmentId(e.target.value)} />
    </div>
    </React.Fragment> }
    <div style={{position: 'absolute', bottom: 10, left: 10, right: 10, height: 35}}>
      <Button color='secondary' onClick={() => setSelectedSlot(null)} >Close</Button>
      { !slotted && <Button color='primary' onClick={assignSlot} >Assign</Button> }
      { slotted && <Button color='primary' onClick={unassignSlot} >UnAssign</Button> }
    </div>
  </Container>
}