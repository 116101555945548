import React, { useEffect, useState } from 'react';
import api from '../../stores/api';
import NoteContent from '../../components/NoteContent';
import styles from './styles';

const TicketNote = ({ ticketId, userId }) => {
  const [notes, setNotes] = useState([]);
  const loadData = (ticketId, userId) => {
    api.get(`/notes/TK_${ticketId}`).then(resp => {
      if (resp.ok) {
        if (resp.data && resp.data.length > 0) {
          setNotes(resp.data);
        } else {
          setNotes([]);
        }
      } else {
        setNotes([]);
      }
    });
  };
  useEffect(() => {
    loadData(ticketId, userId)
  }, [ticketId, userId]);

  return (
    <div style={styles.container}>
      <NoteContent
        title='Ticket Note'
        notes={notes}
      />
    </div>
  );
};
export default React.memo(TicketNote);
