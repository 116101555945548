export default {
  container: {
      maxWidth: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative'
  },
  navigator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: 10,
    activeItem: {
      padding: '10px 20px',
      backgroundColor: '#aeaefe',
    },
    inactiveItem: {
      padding: '10px 20px',
      textDecoration: 'none',
      color: '#222',
      backgroundColor: '#efefef'
    },
  }
}
