import { observable, action, computed } from 'mobx'
import moment from 'moment'

class DriverStore {
    @observable activeDriver = {}
    @observable statistics = []
    @observable activeRoutes = []
    @observable pickupSlots = []
    @observable loadingDriver = false

    constructor(logger, api) {
        this.logger = logger;
        this.api = api;
    }

    @action
    loadDriver(id) {
        this.loadingDriver = true
        this.activeRoutes = []
        this.statistics = {}
        this.api.get(`/drivers/${id}`).then(r => {
            this.activeDriver = r.data
            this.loadingDriver = false
            this.loadDriverAssignments()
            this.loadDriverStatistics()
            this.loadDriverPickups()
        })
    }

    @action
    loadDriverAssignments() {
        if (!this.activeDriver) return
        const today = moment().startOf('day')
        const tommorow = moment().startOf('day').add(1, 'day').unix() * 1000
        this.api.get(`/assignments/drivers/${this.activeDriver.id}?size=10&timestamp=${tommorow}`).then(r => {
            this.activeRoutes = r.data.items.map(x => x.assignment)
                .filter(x => moment(x.predicted_start_ts).isAfter(today))
                .filter(x => x.warehouse_id)
        })
    }

    @action
    loadDriverStatistics() {
        if (!this.activeDriver) return
        this.api.get(`/drivers/${this.activeDriver.id}/statistics`).then(r => {
            this.statistics = r.data
        })
    }

    @action
    loadDriverPickups() {
        if (!this.activeDriver) return
        const today = moment().startOf('day').unix()
        const tommorow = moment().startOf('day').add(1, 'day').unix()
        this.api.get(`/pickup-slots/drivers/${this.activeDriver.id}?start=${today}&end=${tommorow}`).then(r => {
            this.pickupSlots = r.data
        })
    }

    @computed
    get allTimeRouteCount() {
        return (this.statistics && this.statistics.length > 1) ? this.statistics[0].total_assignments : 0
    }
}
export default DriverStore