export default {
    container: {
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative'
    },
    box: {
        padding: '2px',
        margin: '10px 0px',
        textAlign: 'left',
        backgroundColor: 'white',
        border: 'solid 1px #aaa',
        borderRadius: '3px'
    }
}