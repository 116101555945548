export default {
  container: {
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'scroll'
  },
  instructions: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#666'
  }
}