import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AxlButton, AxlLoading, STATUS_COLOR_CODE } from 'axl-reactjs-ui';
// import _ from 'lodash'
import styles from './styles'
import Moment from 'react-moment'

@inject('driverStore', 'userStore')
@observer
class Driver extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this)
        this.gotoRoute = this.gotoRoute.bind(this)
    }

    componentDidMount() {
        this.loadDriver()
    }
    
    loadDriver() {
        const { match, driverStore } = this.props
        const { params } = match
        driverStore.loadDriver(params.id)
    }
    
    goBack() {
        const { history } = this.props
        history.push('/routes')
    }

    gotoRoute(id) {
        const { history } = this.props
        history.push(`/routes/${id}`)
    }

    render() {
        const { driverStore, userStore } = this.props
        const { warehouses } = userStore
        const warhouseIds = warehouses.map(w => w.id)
        const { loadingDriver, activeDriver, activeRoutes = [], allTimeRouteCount = 0, pickupSlots = [] } = driverStore
        const routes = activeRoutes.filter(a => warhouseIds.indexOf(a.warehouse_id) >= 0)
        const isRegistered = pickupSlots && pickupSlots.length > 0 && pickupSlots[0].slot_id !== 'UNREGISTERED'
        return <div style={styles.container}>
            <div style={{textAlign: 'left'}}>
                <AxlButton compact={true} style={{width: '200px'}} onClick={this.goBack}>Go Back</AxlButton>
            </div>
            { loadingDriver && <div style={{position: 'fixed', top: 0, left: 0, right:0, bottom: 0, zIndex: 1000, opacity: 0.5, backgroundColor: '#888', textAlign: 'center', padding: '40px'}}>
                <AxlLoading></AxlLoading>
            </div> }
            { !loadingDriver && activeDriver && <div> <div style={styles.box}>
                <div style={{display: 'flex'}}>
                    <div style={{width: '120px'}}>
                        <img src={activeDriver.photo} style={{maxWidth: 120, maxHeight: 180}} />
                    </div>
                    <div style={{ textAlign: 'left'}}>
                        <div style={{margin: 8}}>Driver Name: <strong style={{ color: '#000'}}>{activeDriver.first_name} {activeDriver.last_name}</strong></div>
                        <div style={{margin: 8}}>Driver License: <code style={{fontWeight: 'bold', fontSize: 15, backgroundColor: '#efefef', color: '#000', padding: '2px 6px', borderRadius: 4}}>{activeDriver.driver_license}</code></div>
                        <div style={{margin: 8}}>License Plate: <code style={{fontWeight: 'bold', fontSize: 15, backgroundColor: '#efefef', color: '#000', padding: '2px 6px', borderRadius: 4}}>{activeDriver.vehicle_license_plate}</code></div>
                        <div style={{margin: 8}}>AxleHire ID: <code style={{fontWeight: 'bold', fontSize: 15, backgroundColor: '#efefef', color: '#000', padding: '2px 6px', borderRadius: 4}}>{activeDriver.id}</code></div>
                        <div style={{margin: 8}}>Phone Number: <code style={{fontWeight: 'bold', fontSize: 15, backgroundColor: '#efefef', color: '#000', padding: '2px 6px', borderRadius: 4}}>{activeDriver.phone_number}</code></div>
                    </div>
                </div>
                <div style={{ borderTop: 'solid 1px #eee', padding: 10, backgroundColor: '#f8f8f8' }}>
                    { allTimeRouteCount > 3 ? <span style={{fontWeight: 'bold', color: '#128'}}>Old Driver. </span> : <span style={{fontWeight: 'bold', color: '#821'}}>New Driver. </span>}
                    <code style={{fontWeight: 'bold', fontSize: 16, backgroundColor: '#fff', padding: 4, borderRadius: 3, border: 'solid 1px #eee'}}>{ allTimeRouteCount }</code> Routes done so far.
                </div>
                <div style={{textAlign: 'center', padding: 10}}>
                    { !isRegistered && <div style={{ color: '#821' }}>No registered pickup time!</div> }
                    { isRegistered && <div>
                        <div style={{color: '#666'}}>Registered pickup time:</div>
                        { pickupSlots.map(slot => <div style={{ marginTop: 10 }} key={slot.id}>
                            <span style={{fontWeight: 'bold'}}><Moment format={'hh:mm a'} interval={0}>{slot.start}</Moment> - <Moment format={'hh:mm a'} interval={0}>{slot.end}</Moment></span>
                            { slot.pickedUpTs && <span>. Showed up at <span style={{fontWeight: 'bold'}}><Moment format={'hh:mm a'} interval={0}>{slot.pickedUpTs}</Moment></span></span> }
                            { !slot.pickedUpTs && slot.eta && <span>. ETA <Moment format={'hh:mm a'} interval={0}>{slot.eta}</Moment></span> }
                        </div>) }
                    </div>}
                </div>
            </div>
            <div>
                <strong>{ routes ? routes.length : 0 } Routes</strong>
                { routes && routes.map(assignment => <div onClick={() => this.gotoRoute(assignment.id)} style={{...styles.box, ...{padding: '12px 8px', cursor: 'pointer', display: 'flex'}}} key={assignment.id}>
                    <div style={{flex: 1}}>Assignment <strong>{assignment.label}</strong> [{assignment.shipment_count}]</div>
                    <div style={{color: assignment.status ? STATUS_COLOR_CODE[assignment.status] : STATUS_COLOR_CODE['PENDING']}}>
                        { assignment.status || 'PENDING' }
                    </div>
                </div>)}
            </div>
            </div> }
        </div>
    }
}

export default Driver