import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles';
import HistoryEvent from '../../components/HistoryEvent'

@inject('eventStore')
@observer
class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
    };
    const { eventStore, uid } = this.props
    eventStore.loadEvents(uid)
  }

  componentWillReceiveProps(props) {
    const { eventStore, uid } = this.props
    if (props.uid === uid) return
    eventStore.loadEvents(props.uid)
  }

  render() {
    const { eventStore, height } = this.props
    const { events } = eventStore
    return <div style={{...styles.container, ...(height ? {maxHeight: height} : {}) }}>
      <div>
        { events.map((e) => <HistoryEvent key={e.id} event={e} />) }
      </div>
    </div>
  }
}

export default History;