import React, { Component } from 'react';
import { AxlButton, AxlModal, AxlTextArea } from 'axl-reactjs-ui';
import { inject } from 'mobx-react';
import _ from 'lodash';
import TicketLineItem from '../../components/TicketLineItem/index';
import { Icon } from 'semantic-ui-react';

import styles from './styles'

class BookingSessionDriver extends Component {
  render() {
    const { driver } = this.props
    return <div style={{borderBottom: 'solid 1px #f8f8f8'}}>{ driver }</div>
  }
}

@inject('bookingStore', 'userStore')
class BookingSessionDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addingDrivers: false,
    }
    this.onAddDrivers = this.onAddDrivers.bind(this)

    this.driverMap = {}
    const {session} = this.props
    const { drivers } = session || {}
    if (drivers != null) {
      drivers.forEach(d => {
        this.driverMap['DR_' + d.id] = d
      });
    }
  }

  onAddDrivers() {
    const {bookingStore} = this.props
    if (!this.state.drivers) return
    bookingStore.addDrivers(this.state.drivers).then(() => {
      this.setState({addingDrivers: false})
    })
  }

  selectTicket(ticket) {
    // if (ticket.assignment) return;
    return this.props.onSelect && this.props.onSelect(ticket)
  }

  render() {
    const { session } = this.props
    let booked = session.tickets.filter(t => t.holder)
    let byHolders = _.mapValues(_.groupBy(booked, a => a.holder),
      l => Object.assign({}, {
        holder: this.driverMap[l[0].holder],
        tickets: l
      }));
    let bookedDrivers = _.sortBy(byHolders, [a => a.holder.id])
    let bookedDriverIds = bookedDrivers.map(b => b.holder.id)
    let unbookedDriverIds = session.session.subjects.filter(d => bookedDriverIds.indexOf(d) < 0)

    return <div>
      <div><b>{session.session.subjects.length}</b> Drivers in session</div>
      <div style={styles.book}>
        <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
          <b>{bookedDrivers.length} Booked drivers</b>
        </div>
        { bookedDrivers.map(driver => <div key={driver.holder.id} style={{borderTop: 'solid 1px #ccc'}}>
          <div style={{backgroundColor: '#f8f8f8', display: 'flex'}}>
            <div style={{flex: 1, textAlign: 'left'}}>
              <span style={{backgroundColor: '#efefef', padding: '2px 4px', display: 'inline-block', fontSize: '0.8em', minWidth: 40, fontWeight: 600, textAlign: 'center'}}>{driver.holder.id}</span> { driver.holder.first_name } { driver.holder.last_name }
            </div>
            <div style={{padding: '2px 8px', fontSize: '0.8em', color: '#888'}}>
              <Icon name={'phone'} />{ driver.holder.phone_number }
            </div>
          </div>
          {driver.tickets.map((ticket) => <div key={ticket.id} onClick={() => this.selectTicket(ticket)}>
          <TicketLineItem canViewDispatch={this.props.canViewDispatch}
          driver={this.driverMap[ticket.holder]}
          ticket={ticket} /></div>) }        
        </div>) }
      </div>
      <div style={styles.book}>
        <div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10, borderBottom: 'solid 1px #ddd'}}>
          <div style={{'flex': 1, paddingTop: 10}}>
            <b>Unbooked drivers</b>
          </div>
          <div style={{minWidth: 120}}>
            <AxlButton compact={true} circular={true} bg={'black'} block={true} onClick = { () => this.setState({addingDrivers: true, drivers: ''}) }>Add Drivers</AxlButton>
          </div>
        </div>

      { unbookedDriverIds.map(driver => <BookingSessionDriver key={driver} driver = {driver} />) }
      </div>

      { this.state.addingDrivers && <AxlModal onClose={() => this.setState({addingDrivers: false}) } style={{width: 600, minHeight: 200, maxHeight: 800, paddingBottom: 60, paddingTop: 20}}>
        <h4>Adding Drivers to Session</h4>
        <div style={{padding: 10}}>
          <AxlTextArea style={{width: '100%', height: '100px'}} name='drivers' onChange={(v) => this.setState({drivers: v.target.value})} />
        </div>
        <div style={{display: 'flex', padding: 10}}>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} onClick={() => this.onAddDrivers()} >Save</AxlButton>
          </div>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({addingDrivers: false})}>Cancel</AxlButton>
          </div>
        </div>
      </AxlModal> }
    </div>
  }
}

export default BookingSessionDrivers