import { create } from 'apisauce';

export class SortLayoutAPI {
  constructor(base) {
    this.api = create({
      baseURL: base,
      withCredentials: true
    });
  }

  withBaseUrl(base) {
    this.api = create({
      baseURL: base,
      withCredentials: true
    });
  }

  loadCenter (id) {
    return this.api.get(`/sortation/sort-center/${id}`).then((r) => r.data)
  }

  loadActiveSessionsByCenter (id, ts) {
    return this.api.get(`/sortation/sort-session/active?center=${id}`).then((r) => r.data)
  }

  loadSessionDetail (id) {
    return this.api.get(`/sortation/sort-session/${id}/detail`).then((r) => this.processSession(r.data))
  }

  loadSessionSlots (id) {
    return this.api.get(`/sortation/sort-session/${id}/slots`).then((r) => r.data)
  }

  loadSession (id) {
    return this.api.get(`/sortation/sort-session/${id}`).then((r) => r.data)
  }

  assignSlot(id, assignments, zone) {
    let payload = {ids: assignments}
    if (zone) {
      payload['attributes'] = { zone }
    }
    return this.api.post(`/sortation/slot/${id}/assign`, payload)
  }

  unassignSlot(id) {
    return this.api.post(`/sortation/slot/${id}/unassign`)
  }

  updateSlotStatus(id, status) {
    return this.api.put(`/sortation/slot/${id}/status`, status)
  }
}

export const sortLayoutAPI = new SortLayoutAPI()
