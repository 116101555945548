export default {
    container: {
  
    },
    group: {
      borderRadius: '6px',
      overflow: 'hidden',
      backgroundColor: 'white',
      margin: '12px 0px',
      boxShadow: '0px 0px 1px #666'
    },
    groupHeader: {
      padding: '4px',
      backgroundColor: '#efefef',
      fontWeight: 'bold',
      fontSize: '14px',
      borderBottom: 'solid 1px #ddd'
    },
    groupBody: {
      padding: '8px'
    },
    slotContainer: {
      display: 'inline-block',
    },
    slot: {
      width: '190px',
      padding: '4px',
      boxSizing: 'border-box',
      margin: 3,
      minHeight: '40px',
      border: 'solid 1px #eee',
      backgroundColor: '#fefefe',
      borderRadius: 4,
      cursor: 'pointer',
    },
    pickedup: {
      backgroundColor: '#efe'
    },
    pickedupLate: {
      backgroundColor: '#fee'
    },
    time: {
      color: '#888',
      fontSize: '0.9em'
    }
  }
  