export default {
  container: {
    borderRadius: '12px 0px 12px 0px',
    backgroundColor: '#fafafa',
    position: 'relative',
    margin: '4px 0px',
    display: 'flex',
    alignItems: 'stretch'
  },
  subject: {
    backgroundColor: '#eee',
    width: '120px',
    borderRadius: '12px 0px 0px 0px',
    padding: '8px 12px',
    textAlign: 'left',
    time: {
      fontSize: '0.8em',
      color: '#888',
      paddingBottom: '4px'
    }
  },
  body: {
    flex: 1,
    textAlign: 'left',
    padding: '4px 8px',
    alignSelf: 'center',
    action: {
      fontWeight: 600
    },
    subtitle: {
      color: '#444'
    }
  }
}