import { observable, action } from 'mobx';
// import _ from 'lodash'

class BookingStore {
  @observable activeSession = null;
  @observable loading = false;
  @observable activeSessions = [];

  constructor(logger, api, ws) {
    this.logger = logger;
    this.api = api;
  }

  @action
  loadActiveSessions() {
    this.api.get('/booking/active').then((r) => {
      this.activeSessions = r.data;
    })
  }

  @action
  loadSession(id) {
    this.loading = true;
    this.api.get(`/booking/${id.split('_')[1]}/detail`).then((r) => {
      this.activeSession = this.processBookingSession(r.data)
      this.loading = false;
    })
  }

  @action
  processBookingSession(data) {
    let groupMap = {}
    if (data.session.groups) {
      data.session.groups.forEach(group => {
        groupMap[group.id] = group
      })
    }
    let assignmentMap = {}
    if (data.assignments) {
      data.assignments.forEach(a => {
        assignmentMap[a.id] = a
      })
    }
    if (data.tickets) {
      data.tickets.forEach(a => {
        if (a.item && a.item.startsWith('AS_')) {
          let assignment_id = parseInt(a.item.split('_')[1])
          a.assignment = assignmentMap[assignment_id]
        }
      })
    }
    if (data.books) {
      data.books.forEach(book => {
        if (data.assignments) {
          let assignment_ids = book.items.map(a => parseInt(a.split('_')[1]))
          book.assignments = data.assignments.filter(a => assignment_ids.indexOf(a.id) >= 0)
        }
        let group = groupMap[book.id]
        if (group && data.tickets) {
          let ticket_ids = group.items.map(a => a.split('_')[1])
          book.tickets = data.tickets.filter(a => ticket_ids.indexOf(a.id) >= 0)
        }
      });
    }
    return data
  }

  @action
  sendPickupTime() {
    this.loading = true
    let pickupSession = this.activeSession.session.attributes.pickup_session
    if (pickupSession)
    this.api.post(`/mq/queue/schedule_driver_pickup`, `BS_${pickupSession}`).then((r) => {
      this.loading = false
    })
  }

  @action
  updateMaxReservation(limit) {
    this.loading = true
    return this.api.patch(`/booking/${this.activeSession.session.id}`, {max_reservation: limit}).then((r) => {
      if (r.status == 200) {
        this.activeSession.session = r.data
      }
      this.loading = false
    })
  }

  @action
  addDrivers(drivers) {
    this.loading = true
    let driver_ids = drivers.split(/[,\n;]/).filter((s) => s).map((s) => parseInt(s))
    return this.api.post(`/booking/${this.activeSession.session.id}/drivers`, {ids: driver_ids}).then((r) => {
      this.loading = false
      this.loadSession(`BS_${this.activeSession.session.id}`)
    })
  }

  @action
  refreshSession() {
    this.api.post(`/booking/${this.activeSession.session.id}/refresh`).then((_) => {
      this.loadSession(`BS_${this.activeSession.session.id}`)
    })
  }

  @action
  addAssignments(bookId, assignments) {
    this.loading = true
    let assignment_ids = assignments.split(/[,\n;]/).filter((s) => s).map((s) => parseInt(s))
    return this.api.post(`/booking/ticket-book/${bookId}`, {ids: assignment_ids}).then((r) => {
      this.loading = false
      this.refreshSession()
    })
  }

  @action
  refreshBook(bookId) {
    this.loading = true
    return this.api.post(`/booking/ticket-book/${bookId}/refresh`).then((r) => {
      this.loading = false
      // this.refreshSession()
    })
  }

  @action
  sendMessage(message, toAll) {
    this.loading = true
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce?all=${toAll}`, message).then((r) => {
      this.loading = false
    })
  }

  @action
  sendTicketGroupMessage(message, book) {
    this.loading = true
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce?book=${book}`, message).then((r) => {
      this.loading = false
    })
  }

}

export default BookingStore