export default {
  container: {
    borderRadius: '16px',
    backgroundColor: 'white',
    padding: '16px',
    position: 'relative',
    minHeight: '400px'
  },
  instructions: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#666'
  }
}