export default {
  container: {
    backgroundColor: 'white',
    borderRadius: '3px', border:
    'solid 1px #aaa',
    margin: '8px 4px',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '13px',
    fontWeight: 'bold'
  },
  innerContainer: {
    display: 'flex',
    padding: '12px 8px',
    zIndex: 100,
    position: 'relative'
  },
  driver_name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1
  },
  region_code:{
    width: '54px',
    textAlign: 'left',
  },
  stats_bar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
  },
  succeeded: {
    height: '100%',
    backgroundColor: '#8fa',
    zIndex: 0
  },
  failed: {
    height: '100%',
    backgroundColor: '#f00',
    opacity: 0.5,
    zIndex: 0
  }
}
