import React, { Component } from 'react';
import styles from './styles';


class AssignmentMiniBox extends Component {
  render() {
    const { detail } = this.props
    const { assignment, count, stats, driver } = detail

    return <div onClick={() => this.props.onSelect && this.props.onSelect(assignment.id)} style={styles.container}>
      <div style={styles.stats_bar}>
        <div style={{...styles.succeeded,...{width: stats.succeeded + '%'}}}></div>
        <div style={{...styles.failed,...{width: stats.failed + '%'}}}></div>
      </div>
      <div style={styles.innerContainer}>
        <div style={styles.region_code}>{assignment.region_code}</div>
        <div style={{width: '70px', textAlign: 'left'}}>{assignment.label}</div>
        { driver && <div style={styles.driver_name}>{driver.first_name} {driver.last_name}</div> }
        { !driver && <div style={styles.driver_name}>...</div> }
        <div style={{width: '50px', textAlign: 'right'}}> {count.completed} / {assignment.shipment_count}</div>
      </div>
    </div>
  }
}

export default AssignmentMiniBox
