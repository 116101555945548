import { observable, action } from 'mobx';
import _ from 'lodash'

class AssignmentStore {
  @observable activeAssignments = [];
  @observable activeAssignmentMap = {}
  @observable selectedRegion = 'All Regions'
  @observable selectedWarehouse = null
  @observable warehouseIds = []

  lastCheck = 0

  idleDuration = 300000;
  idleCompletedDuration = 60000;

  constructor(logger, api, ws) {
    this.logger = logger;
    this.api = api;
    this.ws = ws;
    this.handleShipmentModifier = this.handleShipmentModifier.bind(this)
    this.handleAssignmentModifier = this.handleAssignmentModifier.bind(this)
    this.reloadAssignments = this.reloadAssignments.bind(this)

    ws.subscribe('SHIPMENT_MODIFIER', this.handleShipmentModifier)
    ws.subscribe('ASSIGNMENT_MODIFIER', this.handleAssignmentModifier)
    this.regions = ['SFO', 'LAX', 'SDLAX', 'SEA', 'PDX']
    this.logistic_types = ['NEXT_DAYS']

    // this.activeAssignments = mock.map(this.processAssignmentProgress)
    // mock.forEach(a => this.activeAssignmentMap[a.id] = this.processAssignmentProgress(Object.assign(a, {ts: new Date()})))

    this.reloadAssignments()

    this.reloadHandler = setInterval(() => this.reloadAssignments(), 120000)
    this.toRetrieve = []
    this.toRefresh = []
  }

  @action
  setRegionFilter(r) {
    this.selectedRegion = r
    this.refreshActiveAssignments()
  }

  @action
  setWarehouseFilter(w) {
    this.selectedWarehouse = w
    this.refreshActiveAssignments()
  }

  @action
  setWarehousesFilter(w) {
    this.warehouseIds = w
    this.refreshActiveAssignments()
  }

  @action
  markOutboundFailed(shipment, reason) {
    return this.api.put(`/outbound/${shipment.id}/${reason}`).then((r) => {
      const shipment = r.data

      let existing = this.activeAssignment.shipments.filter(s => s.id === shipment.id)
      if (existing.length > 0) {
        let shipments = this.activeAssignment.shipments.map(s => {
          if (s.id === shipment.id) {
            return shipment
          }
          return s
        })
        // processing
        let assignment = this.processAssignmentDetail(Object.assign(this.activeAssignment, {shipments}))
        this.activeAssignment = assignment
      }
      return shipment;
    })
  }

  @action
  resetPickup(stop) {
    return this.api.post(`/assignments/${this.activeAssignment.assignment.id}/shipments/${stop.shipment_id}/reset-pickup`).then((r) => {
      if (r.status !== 200)
        return;

      if (stop.corresponding_stop)
        stop.corresponding_stop.status = 'PENDING'
      let existing = this.activeAssignment.shipments.filter(s => s.id === stop.shipment_id)
      if (existing.length > 0) {
        let shipments = this.activeAssignment.shipments.map(s => {
          if (s.id === stop.shipment_id) {
            return Object.assign(s, {status: 'GEOCODED'})
          }
          return s
        })
        let stops = this.activeAssignment.stops.map(s => {
          if (s.id === stop.id) {
            return Object.assign(s, {status: 'PENDING'})
          }
          if (s.id === stop.corresponding_stop_id) {
            return Object.assign(s, {status: 'PENDING'})
          }
          return s
        })

        // processing
        let assignment = this.processAssignmentDetail(Object.assign(this.activeAssignment, {shipments, stops}))
        this.activeAssignment = assignment
      }
      return r;
    })
  }

  @action
  removeFromRoute(stop) {
    return this.api.delete(`/assignments/${this.activeAssignment.assignment.id}/shipments/${stop.shipment_id}`).then((r) => {
      if (r.status !== 200)
        return;

      let shipments = this.activeAssignment.shipments.filter(s => s.id !== stop.shipment_id)
      let stops = this.activeAssignment.stops.filter(s => s.shipment_id !== stop.shipment_id)

      // processing
      let assignment = this.processAssignmentDetail(Object.assign(this.activeAssignment, {shipments, stops}))
      this.activeAssignment = assignment
      return r;
    })
  }

  @action
  handleShipmentModifier(msg) {
    this.logger.debug(msg)
    let comps = msg.split(':')
    let shipmentId = parseInt(comps[0])
    if (comps[1] === 'STATUS_UPDATED') {
      if (this.activeAssignment) {
        let existing = this.activeAssignment.shipments.filter(s => s.id === shipmentId)
        if (existing.length > 0) {
          let shipments = this.activeAssignment.shipments.map(s => {
            if (s.id === shipmentId) {
              s.status = comps[2]
              return s
            }
            return s
          })
          // processing
          let assignment = this.processAssignmentDetail(Object.assign(this.activeAssignment, {shipments}))
          this.activeAssignment = assignment
        }
      }
      if (comps.length >= 4) {
        const assignmentId = parseInt(comps[3])
        if (comps[2].indexOf('PICKUP') >= 0) {
          /*
           if (!this.activeAssignmentMap[assignmentId]) {
            this.retrieveAssignment(assignmentId)
           } else {
            this.refreshAssignment(assignmentId)
           }
           */
        } else {
          delete this.activeAssignmentMap[assignmentId]
          this.refreshActiveAssignments()
        }
      }
    }
  }

  @action
  handleAssignmentModifier(msg) {
    this.logger.debug(msg)
    let comps = msg.split('::')
    if (comps.length < 3) return
    if (comps[1] === 'UPDATED' && (comps[2].indexOf('pickupShipment') >= 0 || comps[2].indexOf('PICK_UP') >= 0)) {
      let assignmentId = parseInt(comps[0])
      if (!this.activeAssignmentMap[assignmentId]) {
        this.retrieveAssignment(assignmentId)
       } else {
        this.refreshAssignment(assignmentId)
       }
    }
  }

  retrieveAssignment_(id) {
    this.api.get(`/assignments/${id}/info`).then(r => {
      const a = r.data
      if (this.regions.indexOf(a.assignment.region_code) < 0) return
      if (this.logistic_types.indexOf(a.assignment.logistic_type) < 0) return
      a.ts = new Date()
      this.activeAssignmentMap[a.assignment.id] = this.processAssignmentProgress(a)
      this.refreshActiveAssignments()
    })
  }

  @action
  retrieveAssignments_(ids) {
    this.toRetrieve.forEach(id => {
      this.retrieveAssignment_(id)
    })
    this.toRetrieve = []
  }

  retrieveAssignments = _.debounce(this.retrieveAssignments_, 2000, {'maxWait': 3000})

  retrieveAssignment(id) {
    if (this.toRetrieve.indexOf(id) < 0) this.toRetrieve.push(id)
    this.retrieveAssignments()
  }


  refreshAssignment_(id) {
    if (!this.activeAssignmentMap[id]) return
    this.api.get(`/assignments/${id}/extra`).then(r => {
      const a = r.data
      if (!this.activeAssignmentMap[id]) return
      let assignment = this.activeAssignmentMap[id]
      assignment.extra = a
      this.activeAssignmentMap[id] = this.processAssignmentProgress(assignment)
      a.ts = new Date()
      this.refreshActiveAssignments()
    })
  }

  @action
  refreshAssignments_(ids) {
    this.toRefresh.forEach(id => {
      this.refreshAssignment_(id)
    })
    this.toRefresh = []
  }

  refreshAssignments = _.debounce(this.refreshAssignments_, 2000, {'maxWait': 3000})

  refreshAssignment(id) {
    if (this.toRefresh.indexOf(id) < 0) this.toRefresh.push(id)
    this.refreshAssignments()
  }

  @action
  reloadAssignments() {
    this.api.get(`/assignments/picking-up`).then(r => {
      if (r.status !== 200) {
        this.activeAssignmentMap = {}
        this.refreshActiveAssignments()
        return
      }
      const assignments = r.data
        .filter(a => this.regions.indexOf(a.assignment.region_code) >= 0)
        .filter(a => this.logistic_types.indexOf(a.assignment.logistic_type) >= 0)
        .map(this.processAssignmentProgress)
        .map(a => Object.assign(a, {ts: new Date()}))
      let assignmentMap = {}
      assignments.forEach(a => assignmentMap[a.assignment.id] = a)
      this.activeAssignmentMap = assignmentMap
      this.refreshActiveAssignments()
    })
  }

  processAssignmentProgress(assignment) {
    const { extra } = assignment
    const pickup_status = extra && extra.dropoff_status ? extra.dropoff_status.split('|').filter(s => s[0] === 'P') : []
    const count = {
      total: pickup_status.length,
      succeeded: pickup_status.filter(s => s === 'PS').length,
      failed: pickup_status.filter(s => s === 'PF').length,
      completed: pickup_status.filter(s => s === 'PS' || s === 'PF').length,
    }
    let stats = {
      succeeded: (count.total ? (100 * count.succeeded / count.total) : 0.0).toFixed(1),
      failed: count.total ? (100 * count.failed / count.total).toFixed(0) : 0.0
    }
    if (stats.completed === count.total) {
      stats.failed = 100 - stats.succeeded
    }
    return Object.assign(assignment, {count, stats})
  }

  refreshActiveAssignments() {
    let activeAssignments = []
    const now = new Date()
    for (var i in this.activeAssignmentMap) {
      if (this.selectedRegion && this.selectedRegion.indexOf('All') < 0 && this.activeAssignmentMap[i].assignment.region_code !== this.selectedRegion) continue
      if (!this.activeAssignmentMap[i].assignment) continue
      if (this.warehouseIds.indexOf(this.activeAssignmentMap[i].assignment.warehouse_id) < 0) continue
      if (this.activeAssignmentMap[i].count.completed === this.activeAssignmentMap[i].total) {
        if (now - this.activeAssignmentMap[i].ts < this.idleCompletedDuration) {
          activeAssignments.push(this.activeAssignmentMap[i])
        }
      } else if (now - this.activeAssignmentMap[i].ts < this.idleDuration)
        activeAssignments.push(this.activeAssignmentMap[i])
    }
    this.activeAssignments = _.reverse(_.sortBy(activeAssignments, a => a.assignment.region_code + ":" + a.assignment.id))
  }

  @observable activeAssignment;
  @observable loadingAssignment = false;
  showingStopTypes = ['DROP_OFF']
  inboundFailedStatus = ['MISSING', 'RECEIVED_DAMAGED', 'RECEIVED_LEAKING']

  @action
  loadAssignment(id) {
    this.loadingAssignment = true;
    return this.api.get(`/assignments/${id}/detail?pickup=true`)
      .then(response => {
        this.loadingAssignment = false;
        if (response.status === 200) {
          this.activeAssignment = this.processAssignmentDetail(response.data);
          this.getHistory(id).then(r => {
            this.activeAssignment.assignmentHistory = r.data
          })
          return this.activeAssignment
        } else {
          return {}
        }
      })
  }

  @action
  loadAssignmentFromShipment(shipmentId) {
    this.loadingAssignment = true;
    return this.api.get(`/shipments/${shipmentId}`)
      .then(response => {
        let shipment = response.data
        let assignmentId = shipment.assignment_id
        return assignmentId
      })
  }

  @action
  loadAssignmentFromInternalId(shipmentId) {
    this.loadingAssignment = true;
    return this.api.post(`/shipments/search`, {q: shipmentId, size: 2})
      .then(response => {
        let data = response.data
        if (data.total != 1) return null;
        let shipment = data.results[0].shipment
        let assignmentId = shipment.assignment_id
        return assignmentId
      })
  }

  isInboundUnscanned = (s) => _.isNil(s.shipment.inbound_status)
  isInboundFailed = (s) => s.shipment.inbound_status && this.inboundFailedStatus.indexOf(s.shipment.inbound_status) >= 0
  isCompleted = (s) => this.inboundFailedStatus.indexOf(s.shipment.inbound_status) < 0 && (s.status === 'SUCCEEDED' || s.status === 'FAILED')
  isPickupSucceded = (s) => !this.isCompleted(s) && ((!_.isNil(s.corresponding_stop ) && s.corresponding_stop.status === 'SUCCEEDED') || s.shipment.status === 'PICKUP_SUCCEEDED' || s.shipment.status === 'DROPOFF_SUCCEEDED')
  isPickupFailed = (s) => (!_.isNil(s.corresponding_stop ) && s.corresponding_stop.status === 'FAILED') || s.shipment.status === 'PICKUP_FAILED'
  isNotPickuped = (s) => !_.isNil(s.corresponding_stop ) && (_.isNil(s.corresponding_stop.status) || s.corresponding_stop.status === 'PENDING' || s.corresponding_stop.status === 'READY' || s.corresponding_stop.status === 'EN_ROUTE')
  isScanned = (s) => s.corresponding_stop && s.corresponding_stop.delivery_status && s.corresponding_stop.delivery_status['received'] === 'true' && s.corresponding_stop.delivery_status['receive-status'] === 'ok'

  processAssignmentDetail(assignmentDetail) {
    var shipmentMap = {}
    var shipmentLabelMap = {}
    var clientMap = {};
    const stopMap = {};
    assignmentDetail.shipments.forEach(s => {
        shipmentMap[s.id] = s;
    });
    assignmentDetail.shipmentLabels.forEach(s => {
        shipmentLabelMap[s.shipment_id] = s;
    });
    assignmentDetail.clients.forEach(s => {
        clientMap[s.id] = s;
    });

    assignmentDetail.stops.forEach(s => {
      stopMap[s.id] = s;
    });

    // calculate bbox. Will be part of assignment in the future
    let lats = assignmentDetail.shipments.map(s => s.dropoff_address.lat)
    let lngs = assignmentDetail.shipments.map(s => s.dropoff_address.lng)
    if (assignmentDetail.assignment.logistic_type === 'ON_DEMAND') {
        lats = lats.concat(assignmentDetail.shipments.map(s => s.pickup_address.lat))
        lngs = lngs.concat(assignmentDetail.shipments.map(s => s.pickup_address.lng))
    }
    assignmentDetail.bbox = [[_.min(lngs), _.min(lats)], [_.max(lngs), _.max(lats)]]

    assignmentDetail.stops.forEach(stop => {
        stop.shipment = shipmentMap[stop.shipment_id];
        stop.label = shipmentLabelMap[stop.shipment_id];

        //@TODO: move DROP_OFF into constant
        if (this.showingStopTypes.indexOf(stop.type) >= 0) {
          stop.corresponding_stop = stop.corresponding_stop_id && stopMap[stop.corresponding_stop_id] ? stopMap[stop.corresponding_stop_id] : null;
        }

        if (stop.shipment)
            stop.client = clientMap[stop.shipment.client_id];
    })

    assignmentDetail.pickup_pending = assignmentDetail.stops.filter(s => this.showingStopTypes.indexOf(s.type) >= 0)
      .filter(this.isNotPickuped)
      .filter(x => !this.isScanned(x))
      .filter(s => !this.isInboundFailed(s) && !this.isInboundUnscanned(s) && !this.isPickupSucceded(s) && !this.isPickupFailed(s))

    assignmentDetail.pickup_failed = assignmentDetail.stops.filter(s => this.showingStopTypes.indexOf(s.type) >= 0)
      .filter(this.isPickupFailed)
      .filter(s => !this.isInboundFailed(s) && !this.isInboundUnscanned(s))

    assignmentDetail.pickup_succeeded = assignmentDetail.stops.filter(s => this.showingStopTypes.indexOf(s.type) >= 0)
      .filter((s) => this.isPickupSucceded(s) || (!this.isInboundFailed(s) && this.isScanned(s)))

    assignmentDetail.completed = assignmentDetail.stops.filter(s => this.showingStopTypes.indexOf(s.type) >= 0)
      .filter(this.isCompleted)

    assignmentDetail.inbound_unscanned = assignmentDetail.stops.filter(s => this.showingStopTypes.indexOf(s.type) >= 0)
      .filter(this.isInboundUnscanned)
      .filter(s => !this.isPickupSucceded(s))
      .filter(s => !this.isScanned(s))
      .filter(s => !this.isCompleted(s))

    assignmentDetail.inbound_failed = assignmentDetail.stops.filter(s => this.showingStopTypes.indexOf(s.type) >= 0)
      .filter(this.isInboundFailed)
      .filter(s => !this.isPickupSucceded(s))
    assignmentDetail.inbound_failed.forEach(s => {
      s.status = 'FAILED'
      s.shipment.status = 'PICKUP_FAILED'
    })

    return assignmentDetail;
  }

  @action
  releaseAssignment() {
    const counts = {
      pickup_pending: this.activeAssignment.pickup_pending.length,
      pickup_succeeded: this.activeAssignment.pickup_succeeded.length,
      pickup_failed: this.activeAssignment.pickup_failed.length,
      inbound_failed: this.activeAssignment.inbound_failed.length
    }
    delete this.activeAssignmentMap[this.activeAssignment.id]
    this.refreshActiveAssignments()
    return this.api.post(`/assignments/${this.activeAssignment.assignment.id}/release`, counts)
  }

  @action
  releasePartialAssignment(shipments) {
    let res = this.api.post(`/assignments/${this.activeAssignment.assignment.id}/release-partial`, {ids: shipments})
    delete this.activeAssignmentMap[this.activeAssignment.id]
    this.refreshActiveAssignments()
    return res;
  }

  @action getHistory_(id) {
    return this.api.get(`/histories/by-ref/assignment/${id}`)
  }

  @action getHistory(id) {
    return this.api.get(`/events/assignments/${id}`)
  }

  @action notifyReady() {
    return this.api.put(`/assignments/${this.activeAssignment.assignment.id}/ready`).then(() => {
      this.getHistory(this.activeAssignment.assignment.id).then(r => {
        this.activeAssignment.assignmentHistory = r.data
      });
    })
  }
}


export default AssignmentStore
