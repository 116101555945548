import styled from 'styled-components';

export const Panel = styled.div`
  box-shadow: 0px 0px 1px #444;
  background-color: white;
  border-radius: 6px;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LayoutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  border: solid 1px #aaa;
  padding: 10px 130px 10px 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`