export default {
  book: {
    backgroundColor: 'white',
    borderRadius: '8px',
    margin: 10,
    paddingTop: 0,
    paddingBottom: 8,
    boxShadow: '0px 0px 2px #888',
    name: {
      padding: '2px',
      flex: 1,
    },
    header: {
      backgroundColor: '#efefef',
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
      borderBottom: 'solid 1px #eee',
      display: 'flex'
    }  
  },
  assignment: {
    display: 'flex',
    padding: '6px',
    borderBottom: 'solid 1px #eee'
  }
}
