import { observable, action, computed } from 'mobx';
import api from './api';

class UserStore {
  @observable user = {};
  @observable warehouses = []

  @action getUser() {
    api.get('/me')
      .then(response => {
        if (response.status === 200) {
          this.user = response.data.user;
          this.loadWarehouses()
        }
      })
  }

  @computed
  get isAdmin() {
    return this.user && this.user.scopes && this.user.scopes.indexOf('admin') >= 0
  }

  @computed
  get isSuperAdmin() {
    return this.user && this.user.scopes && this.user.scopes.indexOf('super-admin') >= 0
  }

  @computed
  get isDispatcher() {
    return this.user && this.user.scopes && this.user.scopes.indexOf('dispatcher') >= 0
  }

  @computed
  get isLeadDispatcher() {
    return this.user && this.user.scopes && this.user.scopes.indexOf('lead-dispatcher') >= 0
  }

  @action loadWarehouses() {
    api.get('/warehouses').then(r => {
      this.warehouses = r.data
    })
  }
}

export default UserStore;
