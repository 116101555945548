import React, { Component } from 'react';
import { AxlModal, AxlButton } from 'axl-reactjs-ui';
import styles from './styles';
import Moment from 'react-moment';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx'
import _ from 'lodash'

@inject("driverPickupStore", "userStore")
class PickupSlot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDetail: false,
      editETA: false,
      eta: null,
      note: props.slot.attributes ? props.slot.attributes.note : ''
    }
    this.show = this.show.bind(this)
  }

  show() {
    this.setState({
      showDetail: true,
      note: '',
      editETA: false
    })
  }

  render() {
    const { slot } = this.props
    const { driver, assignments, end, notes = [], eta } = slot
    const pickedUpTs = slot.pickedUpTs ? slot.pickedUpTs : _.min(assignments.filter(a => a.actual_start_ts).map(a => a.actual_start_ts))
    const styled = pickedUpTs ? (moment(pickedUpTs).isAfter(moment(end).add(30, 'm')) ? styles.pickedupLate : styles.pickedup) : (slot.eta ? { border: 'solid 1px #222' } : {})

    return <div style={styles.slotContainer}>
      <div style={{...styles.slot, ...styled}} onClick={this.show}>
        <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '14px', fontWeight: 'bold', color: '#237'}}>
          { driver && <span>{driver.first_name} {driver.last_name}</span>}
          { !driver && <span>UN-CLAIMED</span>}
        </div>
        <div style={{display: 'flex'}}>
          <div style = {{flex: 1, textAlign: 'left'}}>
            {assignments.map(a => <code key={a.id} style={{padding: '1px 4px', margin: '2px', fontSize: '16px', fontWeight: 'bold', color: a.status === 'COMPLETED' ? '#28881c' : (a.status === 'IN_PROGRESS' ? '#e8703a' : 'black')}}>{a.label}</code>)}
          </div>
          <div style = {{flex: 1, textAlign: 'right'}}>
            { pickedUpTs && <span><i className={'far fa-clock'} /> <code style={{fontSize: '14px', fontWeight: 'bold'}}><Moment format={'hh:mma'}>{pickedUpTs}</Moment></code></span>}
            { !pickedUpTs && slot.eta && <span style={{fontSize: '14px', marginLeft: 8, color: '#822'}}>[eta] <code style={{fontSize: '14px', fontWeight: 'bold'}}><Moment format={'h:mma'}>{ slot.eta }</Moment></code></span>}
          </div>
        </div>
      </div>
      { this.state.showDetail && driver && <AxlModal onClose={() => this.setState({showDetail: false}) } style={{width: 600, minHeight: 200, maxHeight: 800, paddingBottom: 60, paddingTop: 20}}>
        <h4>
          [{driver.id}] {driver.first_name} {driver.last_name}
        </h4>
        { !pickedUpTs && <div style={{position: 'absolute', backgroundColor: '#fee', top: 0, left: 0, padding: 5, fontSize: '14px'}}>
          <div>
            { eta && <span><strong>ETA</strong> <Moment interval={0} format={'hh:mm A'}>{eta}</Moment></span> }
            { !eta && <span style={{color: '#f33'}}>No ETA</span> }
          </div>
        </div>}
        { pickedUpTs && <div style={{position: 'absolute', backgroundColor: '#efe', top: 0, left: 0, padding: 5, fontSize: '14px'}}>
          <strong>Showed up</strong> <Moment interval={0} format={'hh:mm A'}>{pickedUpTs}</Moment>
        </div>}
        <div style={{marginBottom: 10}}>
          Phone Number: {driver.phone_number}
        </div>
        <div style={{marginBottom: 10}}>
          { assignments.map(a => <div key={a.id}>
            Route <a href={`/routes/${a.id}`}><strong style={{color: a.status === 'COMPLETED' ? '#28881c' : (a.status === 'IN_PROGRESS' ? '#e8703a' : 'black')}}>{a.label}</strong></a> [{a.shipment_count}]: { a.status ? a.status : 'PENDING'}
          </div>) }
        </div>
        <div style={{textAlign: 'left'}}>
          { notes.map(note => <div style={{padding: '4px 16px'}} key={note.ts}>
            <span style={styles.time}>[<Moment format={'hh:mma'}>{note.ts}</Moment>]</span>
            <strong> {note.user_name} </strong>
            <span>{ note.type === 'message' ? 'sent message' : 'added note'}</span>: 
            &ldquo; <strong><i>{note.note}</i></strong> &rdquo;
          </div>) }
        </div>

        <div style={{position: 'absolute', bottom: 0, left: 10, right: 10, display: 'flex', justifyContent: 'center'}}>
          <div style={{flex: 1}}>
            <AxlButton style={{display: 'block'}} onClick={() => this.setState({showDetail: false}) } bg={'red'}>Close</AxlButton>
          </div>
        </div>
      </AxlModal>}
    </div>
  }
}

@inject('driverPickupStore', 'userStore')
@observer
class DriverPickupTimeContainer extends Component {
  constructor(props) {
    super(props)
    this.reload = this.reload.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    const { userStore, driverPickupStore } = this.props
    this.timer = setInterval(this.reload, 300000);
    driverPickupStore.setWarehouses(userStore.warehouses);
    this.disposer = autorun( () => {
        driverPickupStore.setWarehouses(userStore.warehouses);
    } );
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.disposer) this.disposer()
  }

  goBack() {
    const { history } = this.props
    history.push('/routes')
  }

  reload() {
    const { driverPickupStore } = this.props
    driverPickupStore.loadPickupTime()
  }

  componentWillReceiveProps(props) {
    if (props.match.params.region !== this.props.match.params.region) {
      const { driverPickupStore } = this.props
      driverPickupStore.selectRegion(props.match.params.region)
    }
  }

  render() {
    const { driverPickupStore } = this.props
    const { pickupInfo = {} } =  driverPickupStore
    const { groups = [] } = pickupInfo
    return <div>
        <div style={{textAlign: 'left'}}>
            <AxlButton compact={true} style={{width: '200px'}} onClick={this.goBack}>Go Back</AxlButton>
        </div>
      { groups.map(g => <div style={styles.group} key={g.id}>
        <div style={styles.groupHeader}>
          { g.start && g.end && <span><Moment format={'hh:mm A'}>{g.start}</Moment> - <Moment format={'hh:mm A'}>{g.end}</Moment></span> }
          { (!g.start || !g.end) && <span>NON-REGISTERED</span> }
        </div>
        <div style={styles.groupBody}>
          { g.slots.map((s) => <PickupSlot slot={s} key={s.driver_id} />) }
        </div>
      </div>) }
    </div>
  }
}

export default DriverPickupTimeContainer
