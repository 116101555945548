import React, { Component, Fragment } from 'react';
import styles from './styles'
import TicketLineItem from '../../components/TicketLineItem/index';
import _ from 'lodash';
import { AxlCheckbox } from 'axl-reactjs-ui';
import { Icon } from 'semantic-ui-react';

class TicketListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompleted: false,
      showClaimed: false
    }
  }

  selectTicket(ticket) {
    return this.props.onSelect && this.props.onSelect(ticket)
  }

  render() {
    const { drivers, tickets } = this.props
    this.driverMap = {}
    if (drivers != null) {
      drivers.forEach(d => {
        this.driverMap['DR_' + d.id] = d
      });
    }
    let booked = _.sortBy(tickets.filter(t => t.holder).filter(t => this.state.showCompleted || t.status !== 'COMPLETED').filter(t => this.state.showClaimed || t.status !== 'CLAIMED'), [a => a.valid_from])
    let unbooked = _.sortBy(tickets.filter(t => !t.holder), [a => a.book])
    let byHolders = _.mapValues(_.groupBy(booked, a => a.holder),
      l => Object.assign({}, {
        holder: this.driverMap[l[0].holder],
        time: l[0].valid_from,
        tickets: l
      }));
    this.groups = _.sortBy(byHolders, [a => a.time, a => a.holder.id])
    this.tickets = _.sortBy(booked, [a => a.holder, a => a.valid_from])

    return <Fragment>
      { this.groups && this.groups.length > 0 && <div style={styles.list}>
      <div style={{display: 'flex', backgroundColor: '#efefef', padding: '10px', borderTopRightRadius: 8, borderTopLeftRadius: 8}}>
        <div style={{flex: 1}}>
        <AxlCheckbox theme={`main`} color={`white`} defaultChecked={this.state.showCompleted} onChange={v => this.setState({showCompleted: v.target.checked})} >Show Completed</AxlCheckbox>
        </div>
        <div style={{flex: 1}}>
          <AxlCheckbox theme={`main`} color={`white`} defaultChecked={this.state.showClaimed} onChange={v => this.setState({showClaimed: v.target.checked})} >Show Claimed</AxlCheckbox>
        </div>
      </div>
      { this.groups.map(group => <div key={group.holder.id} style={{borderTop: 'solid 1px #bbb'}}>
        <div style={{fontWeight: 600, paddingTop: 6, paddingBottom: 5}}>
          {group.holder.first_name} {group.holder.last_name} <span style={{fontSize: '0.8em', color: '#666', fontWeight: 500}}>[AHID: {group.holder.id}]</span>
        </div>
        <div style={{color: '#348', fontSize: '0.8em', display: 'flex'}}>
          <div style={{flex: 1}}><span><Icon name={'car'} /> [{group.holder.vehicle_type || 'N/A'}] </span>
          <span> {group.holder.vehicle_make} {group.holder.vehicle_model}</span>
          </div>
          <div style={{flex: 1}}><span style={{marginLeft: 8}}> <Icon name={'phone'} /> { group.holder.phone_number } </span></div>
        </div>
        {group.tickets.map((ticket) => <div key={ticket.id} onClick={() => this.selectTicket(ticket)}>
          <TicketLineItem onClick={() => this.selectTicket(ticket)}
          driver={this.driverMap[ticket.holder]}
          ticket={ticket} /></div>) }        
      </div>) }
    </div> }
    { unbooked.length > 0 && <div style={styles.list}>
      <div style={{fontWeight: 600, paddingTop: 6, paddingBottom: 5}}>UN-BOOKED</div>
      {unbooked.map((ticket) => <div key={ticket.id} onClick={() => this.selectTicket(ticket)}> <TicketLineItem ticket={ticket} /> </div>)}
    </div> }
    </Fragment>
  }
}

export default TicketListContainer
