import React, { Component } from 'react';
import styles from './styles';
import Moment from 'react-moment';
import _ from 'lodash';
import History from '../../containers/History';
import { AxlButton, AxlTextArea, AxlCheckbox, AxlModal, AxlInput } from 'axl-reactjs-ui';
import { inject } from 'mobx-react';
import { Icon } from 'semantic-ui-react'

@inject('bookingStore', 'eventStore', 'userStore')
class BookingSessionInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sendingMessage: false,
      toAll: false,
      settingLimit: false
    }
    this.onSendMessage = this.onSendMessage.bind(this)
    this.onChangeMaxReservation = this.onChangeMaxReservation.bind(this)
  }

  onSendMessage() {
    const { bookingStore, session, eventStore } = this.props
    bookingStore.sendMessage(this.state.message, this.state.toAll).then(() => {
      eventStore.loadEvents('BS_' + session.session.id)  
    })
    this.setState({sendingMessage: false})
  }

  onChangeMaxReservation() {
    const { bookingStore, session } = this.props
    const { edittingValue } = this.state

    if (edittingValue && edittingValue !== session.session.max_reservation)
    bookingStore.updateMaxReservation(parseInt(edittingValue)).then(() => {
      this.setState({settingLimit: false})
    })
  }

  render() {
    const { session, canMessage, userStore } = this.props
    const { isAdmin, isLeadDispatcher } = userStore
    const unbook = session.tickets.filter(t => !t.holder).length
    const claimed = session.tickets.filter(t => t.status === 'CLAIMED').length
    const completed = session.tickets.filter(t => t.status === 'COMPLETED').length
    const unclaimed = session.tickets.filter(t => t.holder && t.status !== 'CLAIMED' && t.status !== 'COMPLETED').length
    const reserved = _.uniq(session.tickets.filter(t => t.holder && (t.valid_from || t.status === 'CLAIMED' || t.status === 'COMPLETED')).map(t => t.holder)).length
    const unreserved = (session.drivers ? session.drivers.length : 0) - reserved
    if (!session) return <div></div>
    const address = session.session.groups.map(g => g.address).find(a => !!a);
    console.log('address is: ', address);
    return <div style={styles.container}>
      <div style={styles.title}>{ session.session.name }</div>
      <div style={{display: 'flex', alignContent: 'center', paddingBottom: 5}}>
        {address && <div style={{flex: 2, textAlign: 'left'}}>
          <div><img src={require('assets/pickup_location.png')} width={16} style={{verticalAlign:'top', marginRight: 4}} />
            { address.street }
          </div>
          <div style={{paddingLeft: 20}}>
            { address.city }, {address.state}
          </div>
        </div>}
        <div style={{flex: 1}}>
          <img src={require('assets/clock.png')} width={16} style={{verticalAlign:'top', marginRight: 3}} />
          <Moment format={'MMM DD'}>{session.session.target_date}</Moment>
        </div>
      </div>
      <div style={{paddingBottom: 5, borderTop: 'solid 1px #eee'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, paddingTop: 10}}>Tickets: <b>{ session.tickets.length }</b></div>
          <div style={{flex: 1, paddingTop: 10}}>
            Booking limit: <b>{ session.session.max_reservation }</b>
            { (isAdmin || isLeadDispatcher) && <span style={{marginLeft: 8, cursor: 'pointer'}} onClick={() => this.setState({settingLimit: true, edittingValue: session.session.max_reservation})}><Icon name='edit outline' /></span> }
          </div>
        </div>
      </div>
      <div style={{paddingBottom: 5, borderTop: 'solid 1px #eee'}}>
        <div style={{display: 'flex'}}>
          <div style={{...{flex: 1, paddingTop: 10}, ...styles.status.COMPLETED}}><b>{ completed }</b> Completed</div>
          <div style={{...{flex: 1, paddingTop: 10}, ...styles.status.CLAIMED}}><b>{ claimed }</b> Claimed</div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{...{flex: 1, paddingTop: 10}, ...styles.status.PENDING}}><b>{ unclaimed }</b> Unclaimed</div>
          <div style={{...{flex: 1, paddingTop: 10}, ...{color: '#c00'}}}><b>{ unbook }</b> Unbooked</div>
        </div>
      </div>
      <div style={{display: 'flex', borderTop: 'solid 1px #eee'}}>
        <div style={{flex: 1, paddingTop: 10}}><b>{ session.drivers ? session.drivers.length : 0 }</b> Drivers</div>
        <div style={{flex: 1, paddingTop: 10}}><b>{ unreserved }</b> Unreserved</div>
      </div>

      { canMessage && !this.state.sendingMessage && <div>
        <AxlButton bg={'black'} block={true} circular={true} onClick={ () => this.setState({sendingMessage: true, message: ''}) }>Send SMS to Drivers</AxlButton>
      </div>}
      { this.state.sendingMessage && <div style={{backgroundColor: '#f8f8f8', borderRadius: 8 , marginTop: 15, marginBottom: 15, paddingTop: 10}}>
        <div>
          <AxlCheckbox theme={`main`} color={`white`} defaultChecked ={this.state.toAll} onChange={ (v) => this.setState({toAll: v.target.checked})}>Send to All Drivers</AxlCheckbox>
        </div>
        <div>
        <div style={{padding: '4px 15px'}}>
         <AxlTextArea style={{width: '100%', height: '120px'}} name='reason' value={this.state.message} onChange={(v) => this.setState({message: v.target.value})} />
        </div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{ flex: 1 }}><AxlButton block={true} circular={true} onClick={ this.onSendMessage }>Send SMS</AxlButton></div>
          <div style={{ flex: 1 }}><AxlButton block={true} bg={'black'} circular={true} onClick={ () => this.setState({sendingMessage: false}) }>Cancel</AxlButton></div>
        </div>
      </div>}

      { this.state.settingLimit && <AxlModal onClose={() => this.setState({settingLimit: false}) } style={{width: 600, minHeight: 200, maxHeight: 800, paddingBottom: 60, paddingTop: 20}}>
        <h4>Update Booking limit</h4>
        <p>Current limit: <b>{ session.session.max_reservation }</b></p>
        <AxlInput defaultValue={session.session.max_reservation} type={'number'} onChange={(v) => this.setState({edittingValue: v.target.value}) } />
        <div style={{display: 'flex', padding: 10}}>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} onClick={() => this.onChangeMaxReservation()} >Save</AxlButton>
          </div>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({settingLimit: false})}>Cancel</AxlButton>
          </div>
        </div>
        <p>Booking limit controls the maximum number of tickets 1 driver can reserve at any moment, excluding those that have been claimed and <b>COMPLETED</b></p>
      </AxlModal>}

      { session && <History height={200} uid={ 'BS_' + session.session.id} /> }
    </div>
  }
}

export default BookingSessionInfo