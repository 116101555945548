import React from 'react';
import LayoutUnit from './LayoutUnit';
import { layoutTransform, selectedSession } from '../states/SortSession'
import { useRecoilValue } from 'recoil';
import { Typography, IconButton, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { ArrowBackIos } from '@material-ui/icons'
import moment from 'moment'

export default function SortLayout({ center }) {
  const history = useHistory()
  const transform = useRecoilValue(layoutTransform)
  const session = useRecoilValue(selectedSession)
  const { scale, flip } = transform
  const size = (!center ? null : center.size ) || { x: 0.0, y: 0.0 }
  const w = flip ? scale * size.y : scale * size.x
  const h = flip ? scale * size.x : scale * size.y

  const goBack = () => {
    history.push(`/sortation/${center.id}`)
  }
  const goHome = () => {
    history.push(`/`)
  }

  return <React.Fragment>
    <Container>
      <Typography align='left'>
        { session && <IconButton onClick = {goBack} size='small'> <ArrowBackIos /> </IconButton> }
        { !session && <IconButton onClick = {goHome} size='small'> <ArrowBackIos /> </IconButton> }
        <span style={{ display: 'inline-block', padding: 6}}> {center.name} </span>
        { session && <span style={{ display: 'inline-block', padding: 6}}> { moment(session.delivery_date).format('MMMM DD')}</span> }
      </Typography>
    </Container>
    <svg height={h+1} width={w+1} style={{backgroundColor: 'white', border: 'solid 1px #888'}}>
    <g transform={`translate(0 ${flip ? h : 0})`}>
      { center && center.pods && center.pods.map(p => <LayoutUnit key = {p.id} unit = {p} />) }
    </g>
  </svg>
  </React.Fragment>
}