import React from 'react';
import moment from 'moment';
import { Button } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

export default function SessionListItem({session}) {
  const history = useHistory()
  const selectSession = () =>  {
    history.push(`/sortation/${session.sort_center_id}/session/${session.id}`)
  }

  return <Button color='primary' onClick = {selectSession} style={{width: '100%'}}>
    { moment(session.delivery_date).format('MMMM D') }
  </Button>
}