import { observable, action } from 'mobx';
import _ from 'lodash'

class EventStore {
  constructor(api) {
    this.api = api;
  }

  @observable activeObject = null;
  @observable loading = true;
  @observable events = [];

  @action
  loadEvents(uid) {
    this.loading = true;
    this.activeObject = uid;
    this.events = [];
    this.api.get(`/events/${uid}/all`).then((r) => {
      this.events = _.sortBy(r.data, (e) => -e.ts)
      this.loading = false
    })
  }

}

export default EventStore