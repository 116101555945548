import { create } from 'apisauce'
import * as axios from 'axios';
axios.defaults.withCredentials = true;

const api = create({
    baseURL: `${process.env.REACT_APP_API_ROOT}`,
    withCredentials: true
});

api.addResponseTransform(response => {
    if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
        window.location.href = `${process.env.REACT_APP_OAUTH_LOGIN_URL}?next=${encodeURI(window.location.href)}`;
    }
});

export default api;
