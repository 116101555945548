import React from 'react';
import styles from './styles';
import Moment from 'react-moment';

class TicketLineItem extends React.Component {
  constructor(props) {
    super(props)
    this.gotoAssignment = this.gotoAssignment.bind(this)
  }
  gotoAssignment() {
    const { ticket, canViewDispatch } = this.props
    if (!canViewDispatch) return;
    const { assignment } = ticket
    if (!assignment) return;
    const clients = assignment.client_ids ? assignment.client_ids.join(',') : 'all'
    window.open(`https://dispatch.axlehire.com/routes/today/${assignment.region_code}/${clients}/${assignment.id}`, "_blank")
  }

  render () {
    const { ticket } = this.props
    const { assignment } = ticket
    return <div style={{borderTop: 'solid 1px #eee', padding: '10px 12px'}}>
    <div style={{display: 'flex'}}>
      <div style={{flex: 1.5, textAlign: 'left', color: '#222', fontSize: '0.9em'}}>{ ticket.name }</div>
      { !assignment && <div style={{flex: 1, fontSize: '0.8em', color: '#444', fontWeight: 400}}>
        { ticket.valid_from && <Moment format={'h:mma'}>{ticket.valid_from}</Moment> } - { ticket.valid_to && <Moment format={'h:mma'}>{ticket.valid_to}</Moment> }
      </div> }
      { assignment && <div style={{flex: 1, fontSize: '0.8em', color: '#444', fontWeight: 400}}>
        <code style={styles.assignment}>{ assignment.label }</code>
      </div>}
      <div style={{...{flex: 1, fontSize: '0.8em', textAlign: 'right'}, ...styles.status[ticket.status]}}>{ ticket.status }</div>
    </div>
  </div>
  }
}

export default TicketLineItem
