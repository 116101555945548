import React, {useEffect} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { layoutTransform, zoneColorMap, sortCenterId, sortCenter, useSortSession, sortSessionPods, sortCenterActiveSessions, selectedSessionId } from '../states/SortSession'
import { useParams } from 'react-router-dom';
import { CircularProgress, Container, IconButton, Typography } from "@material-ui/core";
import ErrorBoundary from './ErrorBoundary'
import SortLayout from './SortLayout';
import SortZones from './SortZones'
import LayoutScale from './LayoutScale'
import SelectedSlot from './SelectedSlot';
import SortSlotSearch from './SortSlotSearch';
import { Panel, LayoutWrapper } from './styles';
import SessionListItem from './SessionListItem'
import { Refresh } from '@material-ui/icons';

export function SortCenter() {
  const { center_id, session_id } = useParams();
  console.log('SortCenterContainer')

  return (
    <Container>
      <ErrorBoundary>
      <React.Suspense fallback={<CircularProgress />}>
        <SortCenterContainer id = {center_id} session_id = {session_id} />
      </React.Suspense>
      </ErrorBoundary>
    </Container>
  )
}

export function SortCenterContainer({id, session_id}) {
  const loadSlots = useSortSession()
  const selectId = useSetRecoilState(sortCenterId)
  selectId(id)
  loadSlots(session_id)
  console.log('SortCenterOuterContainer', id, session_id)
  
  return (
      <ErrorBoundary>
      <React.Suspense fallback={<CircularProgress />}>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'auto'}}>
          <SortCenterInnerContainer />
        </div>
      </React.Suspense>
      </ErrorBoundary>
  )
}

function SortSettings() {
  const colorMap = useRecoilValue(zoneColorMap)
  const transform = useRecoilValue(layoutTransform)
  const activeSession = useRecoilValue(selectedSessionId)
  const pods = useRecoilValue(sortSessionPods)
  const loadSlots = useSortSession()
  const sessionId = useRecoilValue(selectedSessionId)
  const refresh = () => loadSlots(sessionId)

  useEffect(() => {
    const timer=setTimeout(() => {
      console.log('refresh')
      refresh()
    }, 60000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const { advanced } = transform || {}
  return <React.Fragment>
    {advanced && activeSession && <Panel style={{ top: 50, right: 10, width: 140, padding: 2, textAlign: 'center'}}>
      <SortSlotSearch />
    </Panel> }
    { advanced && activeSession && <Panel style={{ top: 100, right: 10, bottom: 10, overflow: 'auto', fontSize: '13px', width: 140, padding: 2}}>
      { colorMap && <SortZones colorMap = { colorMap } /> }
      { pods && pods.pods && pods.pods.length > 0 && <div style={{backgroundColor: '#f8f8f8', padding: 6, borderRadius: 5, margin: 5, textAlign: 'left'}}>
        <h5>Box Count <IconButton size='small' onClick={refresh}><Refresh /></IconButton></h5>
        <div style={{backgroundColor: '#444', color: '#fff', padding: 5, borderRadius: 5, marginBottom: 5, textAlign: 'center', fontSize: 15}}>
          { pods.completed } / { pods.workload }
        </div>
        {pods.pods.map(pod => <div key={pod.id} style={{paddingTop: 6, paddingBottom: 4, borderTop: 'solid 1px #eee'}}>
          <span style={{display: 'inline-block', minWidth: 50}}>Pod <strong>{ pod.name }:</strong></span> {pod.completed} / {pod.workload}
        </div>)}
      </div>}
    </Panel> }
    { !activeSession && advanced && <SessionList /> }
  </React.Fragment>
}


function SessionList() {
  const sessions = useRecoilValue(sortCenterActiveSessions)
  return <Panel style={{ top: 50, right: 10, bottom: 10, width: 140, padding: 2}}>
    <Typography>Active Session</Typography>
    { sessions && sessions.map(s => <SessionListItem key={s.id} session = {s} />)}
  </Panel>
}

function SortCenterInnerContainer(props) {
  const center = useRecoilValue(sortCenter)
  if (!center) return <div></div>

  return (
    <React.Fragment>
        <LayoutWrapper>
          <SortLayout center = {center} />
        </LayoutWrapper>
        <Panel style={{top: 10, right: 10, width: 140, padding: 2, textAlign: 'center'}}>
          <LayoutScale />
        </Panel>
        <SortSettings />
        <SelectedSlot />
    </React.Fragment>
  )
}