import React, { Component } from 'react';
import styles from './styles';
import moment from 'moment'

class EventSubject extends Component {
  TYPE_NAME = {
    'DR': 'driver',
    'US': 'user',
    'AS': 'assignment',
    'SH': 'shipment',
    'ST': 'stop',
    'BS': 'booking session',
    'TK': 'ticket'
  }
  render() {
    const { subject } = this.props
    if (!subject || !subject.uid) return <span></span>
    const uid_type = subject.uid.split('_')[0]
    const uid_id = subject.uid.split('_')[1]
    const type_name = this.TYPE_NAME[uid_type] || ''
    const name = subject.attributes && subject.attributes.name ? subject.attributes.name : null
    
    return <span style={{fontSize: '0.9em'}}>
      <span>{ type_name }</span> <strong>{ name || uid_id }</strong> { name && <span style={{color: '#888'}}>[{uid_id}]</span> }
    </span>
  }
}

class HistoryEvent extends Component {
  render() {
    const {event} = this.props
    const etype = `${event.category}-${event.type}-${event.action}`
    return <div style={styles.container}>
      <div style={styles.subject}>
        <div style={styles.subject.time}>{ moment(event.ts).format('MM/DD HH:mm:ss') }</div>
        <div> <EventSubject subject={event.subject} /></div>
      </div>
      <div style={styles.body}>
        <div style={{paddingBottom: 4}}>
          <span style={styles.body.action}>{ event.action }</span>
          <span style={styles.body.subtitle}>
            {(etype === 'BOOKING-COMMUNICATION-sms' || etype === 'BOOKING-COMMUNICATION-pn') && event.fact && <span> to { event.fact.all === 'true' ? ' all ' : '' } {event.fact.driver_count} drivers { event.fact && event.fact.book_name && <span>with <code style={{display: 'inline-block', backgroundColor: '#efefff', padding: '0 2px', borderRadius: '3px'}}>{event.fact.book_name}</code> tickets</span>} </span>}
            {etype === 'TICKET-OUTBOUND-claim' && event.fact && event.fact.item && <span> assignment {event.fact.item.split('_')[1]} for driver {event.fact.holder}</span>}
          </span>
        </div>
        <div style={{fontSize: '0.9em'}} >
          {(etype === 'BOOKING-COMMUNICATION-sms' || etype === 'BOOKING-COMMUNICATION-pn') && event.evidence && event.evidence.content && <span dangerouslySetInnerHTML ={{__html: event.evidence.content.replace('\n', '<br />') }} />}
          {etype === 'TICKET-PLANNING-void' && event.ref && <div>from <EventSubject subject={event.ref} /></div>}
        </div>
      </div>
    </div>
  }
}

export default HistoryEvent