export default {
  list: {
    backgroundColor: 'white',
    borderRadius: '8px',
    margin: 10,
    paddingTop: 0,
    paddingBottom: 8,
    boxShadow: '0px 0px 2px #888'
  },
  header_bar: {
    flex: 1,
    height: '1px',
    backgroundColor: '#aaa'
  },
  list_header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '15px'
  }
}
