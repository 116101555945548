export default {
  container: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative'
  },
  leftPanel: {
    width: '360px',
    height: '100%',
    padding: '10px',
    backgroundColor: '#efefef'
  },
  rightPanel: {
    width: 'calc(100% - 360px)',
    height: '100%',
  },
  toggle_panel_btn: {
    position: 'absolute',
    left: '2px',
    top: '-3px',
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    backgroundColor: '#bbb',
    cursor: 'pointer',
    opacity: 0.2
  },
  region_btn_container: {
    position: 'absolute',
    right: '0px',
    top: '0px',
  },
  region_btn: {
    backgroundColor: '#f8f8f8',
    padding: '2px 8px',
    display: 'inline-block',
    borderRadius: '3px',
    cursor: 'pointer',
    minWidth: '40px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold'
  }
}
