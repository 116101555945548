import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AxlButton,  AxlLoading } from 'axl-reactjs-ui';
import styles from './styles'

import AssignmentDetail from '../../containers/AssignmentDetail/index'

@inject('assignmentStore')
@observer
class Assignment extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.loadAssignment = this.loadAssignment.bind(this)
  }

  componentDidMount() {
    this.loadAssignment()
  }

  loadAssignment() {
    const { match, assignmentStore } = this.props
    const { params } = match
    assignmentStore.loadAssignment(params.id)
  }

  goBack() {
    const { history } = this.props
    history.push('/')
  }

  render() {
    const {assignmentStore,history} = this.props
    return <div style={styles.container}>
      {assignmentStore.loadingAssignment && <div style={{position: 'fixed', top: 0, left: 0, right:0, bottom: 0, zIndex: 1000, opacity: 0.5, backgroundColor: '#888', textAlign: 'center', padding: '40px'}}>
        <AxlLoading></AxlLoading>
      </div>}
      <div style={{textAlign: 'left'}}>
        <AxlButton compact={true} style={{width: '200px'}} onClick={this.goBack}>Go Back</AxlButton>
        <AxlButton bg={'bluish'} compact={true} style={{width: '80px', float: 'right'}} onClick={this.loadAssignment}>Refresh</AxlButton>
      </div>
      <AssignmentDetail history={history} />
    </div>
  }
}

export default Assignment
