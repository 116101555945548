import React, { Component, Fragment } from 'react';
import styles from './styles'
import TicketLineItem from '../../components/TicketLineItem/index';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { AxlButton, AxlTextArea, AxlModal } from 'axl-reactjs-ui';
import { inject } from 'mobx-react';

@inject('bookingStore', 'eventStore')
class TicketBookContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingSMS: false
    }
    this.onSendMessage = this.onSendMessage.bind(this)
  }

  selectTicket(ticket) {
    // if (ticket.assignment) return;
    return this.props.onSelect && this.props.onSelect(ticket)
  }

  onSendMessage() {
    const { bookingStore, eventStore, book } = this.props
    bookingStore.sendTicketGroupMessage(this.state.message, book.id).then((v) => {
      eventStore.loadEvents('BS_' + bookingStore.activeSession.session.id)
    })
    this.setState({sendingSMS: false})
  }

  render() {
    const { drivers, book, showBooked, showClaimed, showCompleted, canSMS } = this.props
    if (!book) return 
    const {tickets } = book
    this.driverMap = {}
    const claimedCount = tickets.filter(t => t.status === 'CLAIMED').length
    const completedCount = tickets.filter(t => t.status === 'COMPLETED').length
    if (drivers != null) {
      drivers.forEach(d => {
        this.driverMap['DR_' + d.id] = d
      });
    }
    let booked = _.sortBy(tickets.filter(t => t.holder), [a => a.valid_from])
    let unbooked = _.sortBy(tickets.filter(t => !t.holder), [a => a.book])
    const bookedCount = booked.length
    let byHolders = _.mapValues(_.groupBy(booked, a => a.holder),
      l => Object.assign({}, {
        holder: this.driverMap[l[0].holder],
        time: l[0].valid_from,
        tickets: l.filter(t => showCompleted || t.status !== 'COMPLETED').filter(t => showClaimed || t.status !== 'CLAIMED')
      }));
    this.groups = _.sortBy(byHolders, [a => a.time, a => a.holder ? a.holder.id : 0]).filter(g => g.tickets && g.tickets.length > 0)
    this.tickets = _.sortBy(booked, [a => a.holder, a => a.valid_from])

    return <Fragment>
      <div style={styles.list}>
      <div style={{backgroundColor: '#444', display: 'flex', color: '#fff', padding: '6px', borderTopRightRadius: 8, borderTopLeftRadius: 8}}>
        <div style={{flex: 1, padding: 6}}>{book.name}</div>
        { canSMS && <div><AxlButton compact={true} tiny={true} style={{margin: 0}} bg={'black'} circular={true} block={true} onClick={() => this.setState({sendingSMS: true})}>SMS</AxlButton></div> }
      </div>
      <div style={{display: 'flex', backgroundColor: '#efefef', padding: '6px'}}>
        <div style={{flex: 1}}>
          Completed [{completedCount}]
        </div>
        <div style={{flex: 1}}>
          Claimed [{claimedCount}]
        </div>
        <div style={{flex: 1}}>
          Booked [{bookedCount}]
        </div>
      </div>
      { showBooked &&  this.groups.map(group => <div key={group.holder.id} style={{borderTop: 'solid 1px #bbb'}}>
        <div style={{fontWeight: 600, paddingTop: 6, paddingBottom: 5}}>
          {group.holder.first_name} {group.holder.last_name} <span style={{fontSize: '0.8em', color: '#666', fontWeight: 500}}>[AHID: {group.holder.id}]</span>
        </div>
        <div style={{color: '#348', fontSize: '0.8em', display: 'flex'}}>
          <div style={{flex: 1}}><span><Icon name={'car'} /> [{group.holder.vehicle_type || 'N/A'}] </span>
          <span> {group.holder.vehicle_make} {group.holder.vehicle_model}</span>
          </div>
          <div style={{flex: 1}}><span style={{marginLeft: 8}}> <Icon name={'phone'} /> { group.holder.phone_number } </span></div>
        </div>
        {group.tickets.map((ticket) => <div key={ticket.id} onClick={() => this.selectTicket(ticket)}>
          <TicketLineItem canViewDispatch={this.props.canViewDispatch} onClick={() => this.selectTicket(ticket)}
          driver={this.driverMap[ticket.holder]}
          ticket={ticket} /></div>) }        
      </div>) }
      { unbooked.length > 0 && <div style={{borderTop: 'solid 1px #bbb', backgroundColor: '#fff8f8'}}>
        <div style={{fontWeight: 600, paddingTop: 6, paddingBottom: 5}}>UN-BOOKED [{unbooked.length}]</div>
        {unbooked.map((ticket) => <div key={ticket.id} onClick={() => this.selectTicket(ticket)}> <TicketLineItem ticket={ticket} /> </div>)}        
      </div> }
    </div>

    { this.state.sendingSMS && <AxlModal onClose={() => this.setState({sendingSMS: false}) } style={{width: 600, minHeight: 160, maxHeight: 800, paddingBottom: 0, paddingTop: 20}}>
        <h4>Sending SMS to drivers with { book.name } tickets</h4>
        <div style={{padding: 15}}><AxlTextArea style={{width: '100%', height: 100}} onChange={(v) => this.setState({message: v.target.value}) } /></div>
        <div style={{display: 'flex', padding: 10}}>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} onClick={() => this.onSendMessage()} >SEND</AxlButton>
          </div>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({sendingSMS: false})}>Cancel</AxlButton>
          </div>
        </div>
      </AxlModal>}

    </Fragment>
  }
}

export default TicketBookContainer
