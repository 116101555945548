import { observable, action } from 'mobx';
import moment from 'moment-timezone';

class TicketStore {
  @observable activeTicket = null;
  @observable loadingTicket = false;
  @observable activeDriver = null;
  @observable otherTickets = [];

  constructor(logger, api, locationStore) {
    this.logger = logger;
    this.api = api;
    this.locationStore = locationStore;
  }

  @action
  loadTicket(v) {
    this.loadingTicket = true;
    this.activeDriver = null;
    this.activeTicket = null;
    this.otherTickets = [];
    let comps = v.split('@');
    this.api.get(`/tickets/${comps[1]}`).then((r) => {
      this.activeTicket = r.data
      this.loadingTicket = false
      if (this.activeTicket.holder) {
        const driverId = this.activeTicket.holder.split('_')[1]
        this.locationStore.setDriver(driverId)
          this.api.get(`/drivers/${driverId}`).then((r) => {
            if (r.status == 200)
              this.activeDriver = r.data
          });
        } else {
        this.locationStore.setDriver(null)
      }
    })
    this.api.get(`/tickets/${comps[1]}/related`).then((r) => {
      if (r.status === 200)
        this.otherTickets = r.data.filter(t => t.id !== comps[1].split('_')[1])
      else
        this.otherTickets = []
    });
  }

  @action assignTicket(codes) {
    return this.api.post(`/tickets/TK_${this.activeTicket.id}/assign/${this.activeTicket.holder}`, {codes: codes}).then((r) => {
      if (r.status === 200) {
        this.activeTicket.item = r.data
        this.activeTicket.status = 'CLAIMED'
        return r.data
      } else {
        alert(r.data.message)
        return null
      }
    }).catch((e) => {
      alert(e);
      return null
    });
  }

  @action unassignTicket() {
    return this.api.post(`/tickets/TK_${this.activeTicket.id}/unassign/${this.activeTicket.holder}`).then((r) => {
      if (r.status >= 200 && r.status < 300) {
        this.activeTicket = Object.assign(this.activeTicket, {item: null, status: 'CREATED'})
        return r.data
      } else {
        alert(r.data.message)
        return null
      }
    }).catch((e) => {
      alert(e);
      return null
    });
  }

  availablePickupTimes(tz) {
    const now = Date.now()
    var half_hour = Math.floor(now / 1800000) * 1800000
    let ranges = []
    let limit = moment().tz(tz).startOf('day').add(18, 'hour').unix() * 1000
    while (half_hour <= limit) {
      half_hour += 1800000
      var next = half_hour + 1800000
      var slot = {
        start: half_hour,
        end: next,
        name: moment(half_hour).tz(tz).format('hh:mmA') + ' - ' + moment(next).tz(tz).format('hh:mmA')
      }
      ranges.push(slot)
    }

    return ranges
  }

  @action voidTicket(id) {
    return this.api.post(`/tickets/TK_${id}/void`).then((r) => {
      if (this.acactiveTicket && this.activeTicket.id === id)
        this.activeTicket.status = 'VOIDED'
    });
  }

  @action setPickupTime(id, start, end) {
    return this.api.post(`/tickets/TK_${id}/eta`, [start, end]).then((r) => {
      if (r.status === 200 || r.status === 204) {
      if (this.acactiveTicket && this.activeTicket.id === id) {
        this.activeTicket.valid_from = start
        this.activeTicket.valid_to = end
      }}
    });
  }

  @action
  assignDriver(id, driverId, session) {
    this.loading = true
    return this.api.put(`/tickets/TK_${id}/assign/DR_${driverId}?session=BS_${session}`).then((r) => {
      this.loading = false
    })
  }


}

export default TicketStore