import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AxlButton, AxlLoading } from 'axl-reactjs-ui';
import styles from './styles'
import TicketRelease from '../../containers/TicketRelease/index';
import TicketLineItem from '../../components/TicketLineItem';

// import AssignmentDetail from '../../containers/AssignmentDetail/index'

@inject('ticketStore', 'locationStore')
@observer
class TicketScreen extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.loadTicket = this.loadTicket.bind(this)
    this.gotoTicket = this.gotoTicket.bind(this)
  }

  componentDidMount() {
    this.loadTicket()
  }

  loadTicket() {
    const { match, ticketStore } = this.props
    const { params } = match
    ticketStore.loadTicket(params.id)
  }

  goBack() {
    const { history, ticketStore } = this.props
    const { activeTicket } = ticketStore
    if (activeTicket.attributes.session)
      history.push(`/booking-sessions/${activeTicket.attributes.session}`)
    else
      history.goBack()
  }

  gotoTicket(t) {
    const { history, ticketStore } = this.props
    history.push(`/tickets/${t.holder}@TK_${t.id}`)
    ticketStore.loadTicket(`${t.holder}@TK_${t.id}`)
  }

  componentWillUnmount() {
    const { locationStore } = this.props
    locationStore.setDriver(null)
  }

  render() {
    const { ticketStore } = this.props
    const { otherTickets, activeTicket } = ticketStore
    return <div style={styles.container}>
      {ticketStore.loadingAssignment && <div style={{position: 'fixed', top: 0, left: 0, right:0, bottom: 0, zIndex: 1000, opacity: 0.5, backgroundColor: '#888', textAlign: 'center', padding: '40px'}}>
        <AxlLoading></AxlLoading>
      </div>}

      <div style={{textAlign: 'left', paddingBottom: 10}}>
        <AxlButton circular={true} compact={true} style={{width: '200px'}} onClick={this.goBack}>Go Back</AxlButton>
        <AxlButton circular={true} bg={'bluish'} compact={true} style={{width: '80px', float: 'right'}} onClick={this.loadTicket}>Refresh</AxlButton>
      </div>

      <TicketRelease />

      { activeTicket && otherTickets != null && otherTickets.length > 0 && <div style={{
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 10,
        marginTop: 10
      }}>
        <h4>Other tickets by the same driver</h4>
        { otherTickets.filter(t => t.attributes.session == activeTicket.attributes.session).map(t => <div style={{cursor: 'pointer'}} key={t.id} onClick={() => this.gotoTicket(t)}>
          <TicketLineItem ticket={ t } />
        </div>)}
      </div> }

    </div>
  }
}

export default TicketScreen
