import React from 'react';
import { useSetRecoilState} from 'recoil';
import { IconButton, Container } from "@material-ui/core";
import { ZoomIn, ZoomOut, Rotate90DegreesCcwOutlined, Settings } from '@material-ui/icons';
import { layoutTransform } from '../states/SortSession'

export default function LayoutScale() {
  const setTransform = useSetRecoilState(layoutTransform)

  const setScale = (incr) => () => setTransform((s) => Object.assign({}, s, {scale: s.scale + incr}))
  const setFlip = () => setTransform((s) => Object.assign({}, s, {flip: !s.flip}))
  const setAdvanced = () => setTransform((s) => Object.assign({}, s, {advanced: !s.advanced}))

  return (
    <Container style={{padding: 0}}>
      <IconButton elevation={0} size={'small'} onClick={setFlip}><Rotate90DegreesCcwOutlined/></IconButton>
      <IconButton elevation={0} size={'small'} onClick={setScale(1)}><ZoomIn/></IconButton>
      <IconButton elevation={0} size={'small'} onClick={setScale(-1)}><ZoomOut/></IconButton>
      <IconButton elevation={0} size={'small'} onClick={setAdvanced}><Settings/></IconButton>
    </Container>
  )
}