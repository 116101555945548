import React, { Component, Fragment } from 'react';
import styles from './styles'
import _ from 'lodash';
import { AxlButton, AxlModal, AxlTextArea } from 'axl-reactjs-ui';
import { inject } from 'mobx-react';

@inject('bookingStore')
class BookingSessionBook extends Component {
  constructor(props) {
    super(props)
    const { book, allAsssignments } = this.props
    const ids = book.items.map(i => parseInt(i.split('_')[1]))
    this.assignments = _.sortBy(allAsssignments.filter(a => !a.driver_id).filter(a => ids.indexOf(a.id) >= 0), (a) => a.label)
    this.state = {
      addingAssignments: false
    }
    this.onAddAssignments = this.onAddAssignments.bind(this)
    this.onRefresh = this.onRefresh.bind(this)
  }

  onAddAssignments() {
    const {bookingStore, book} = this.props
    if (!this.state.assignments) return
    bookingStore.addAssignments(book.id, this.state.assignments).then(() => {
      this.setState({addingAssignments: false})
    })
  }

  onRefresh() {
    const {bookingStore, book} = this.props
    bookingStore.refreshBook(book.id);
  }

  render() {
    const { book, canAdd } = this.props
    return <div style={styles.book}>
      <div style={styles.book.header}>
        <div style={styles.book.name}>
          <div>{ book.name }</div>
          <div style={{fontSize: '0.75em', color: '#444'}}>{ book.id }</div>
        </div>
        { canAdd && <div style={{minWidth: 70}}>
          <AxlButton compact={true} circular={true} bg={'black'} block={true} onClick = { () => this.onRefresh() }>Refresh</AxlButton>
        </div> }
        { canAdd && <div style={{minWidth: 60}}>
          <AxlButton compact={true} circular={true} bg={'black'} block={true} onClick = { () => this.setState({addingAssignments: true, drivers: ''}) }>Add</AxlButton>
        </div> }
      </div>
      { this.assignments.map(assignment => <div key={assignment.id} style={styles.assignment}>
        <div style={{flex:1, fontSize: '0.8em', color: '#888'}}>[{assignment.id}]</div>
        <div style={{flex:1, fontWeight: 600}}>{ assignment.label }</div>
        <div style={{flex:1}}>{ assignment.status }</div>
      </div>) }
      { this.state.addingAssignments && <AxlModal onClose={() => this.setState({addingAssignments: false}) } style={{width: 600, minHeight: 200, maxHeight: 800, paddingBottom: 0, paddingTop: 20}}>
        <h4>Adding Assignments to {book.name}</h4>
        <div style={{padding: 10}}>
          <AxlTextArea style={{width: '100%', height: '100px'}} name='drivers' onChange={(v) => this.setState({assignments: v.target.value})} />
        </div>
        <div style={{display: 'flex', padding: 10}}>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} onClick={() => this.onAddAssignments()} >Save</AxlButton>
          </div>
          <div style={{flex: 1}}>
            <AxlButton block={true} circular={true} bg={'black'} onClick={() => this.setState({addingAssignments: false})}>Cancel</AxlButton>
          </div>
        </div>
      </AxlModal> }
    </div>
  }
}

@inject('bookingStore', 'userStore')
class BookingSessionAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompleted: false,
      showClaimed: false
    }
    this.onRefresh = this.onRefresh.bind(this)
  }

  onRefresh() {
    const {bookingStore} = this.props
    bookingStore.refreshSession()
  }

  render() {
    const { session, userStore } = this.props
    const canAddAssignments = userStore.isAdmin

    return <Fragment>
      <div>
        { session.books.map(book => <BookingSessionBook canAdd={canAddAssignments} key={book.id} book = {book} allAsssignments = {session.assignments} session={session} />) }
      </div>
      {canAddAssignments && <div style={{cursor: 'pointer', color: '#ccc', borderRadius: '16px', border: 'solid 1px #ccc', padding: '6px', margin: '10px'}} onClick={() => this.onRefresh()}>Refresh</div>}
    </Fragment>
  }
}

export default BookingSessionAssignments